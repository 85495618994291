import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { TabButton } from "../clientReusableComponents/components";
import faceFront from "../../../assets/images/clients/medication/faceFront.png";
import faceBack from "../../../assets/images/clients/medication/faceBack.png";
import rightFront from "../../../assets/images/clients/medication/rightFront.png";
import leftFront from "../../../assets/images/clients/medication/leftFront.png";
import rightBack from "../../../assets/images/clients/medication/rightback.png";
import leftBack from "../../../assets/images/clients/medication/leftBack.png";
import leftHeel from "../../../assets/images/clients/medication/leftHeel.png";
import leftBackleg from "../../../assets/images/clients/medication/leftBackleg.png";
import rightBackleg from "../../../assets/images/clients/medication/rightBackleg.png";
import rightHeel from "../../../assets/images/clients/medication/rightHeel.png";
import rightFrontleg from "../../../assets/images/clients/medication/rightFrontleg.png";
import leftFrontleg from "../../../assets/images/clients/medication/leftFrontleg.png";
import pelvisFront from "../../../assets/images/clients/medication/pelvisFront.png";
import pelvisBack from "../../../assets/images/clients/medication/pelvisBack.png";
import bodyFront from "../../../assets/images/clients/medication/bodyFront.png";
import bodyBack from "../../../assets/images/clients/medication/bodyBack.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CreateIcon from "@mui/icons-material/Create";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
function convertImagesToBase64(imagePaths) {
  return Promise.all(
    imagePaths.map((imagePath) =>
      fetch(imagePath)
        .then((response) => response.blob()) // Get image as a blob
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result); // Base64 result
              reader.onerror = reject;
              reader.readAsDataURL(blob); // Convert to base64
            })
        )
    )
  );
}

function BodyMap({ setBodyData, setBodyData1, setBodyData2 }) {
  const bodyMapConst = {
    Body: { "Body Front": bodyFront, "Body Back": bodyBack },
    Face: { "Face Front": faceFront, "Face Back": faceBack },
    Hands: {
      "Right Front": rightFront,
      "Right Back": rightBack,
      "Left Front": leftFront,
      "Left Back": leftBack,
    },
    Pelvis: { "Pelvis Front": pelvisFront, "Pelvis Back": pelvisBack },
    Feet: {
      "Right Front": rightFrontleg,
      "Right Back": rightBackleg,
      "Right Heel": rightHeel,
      "Left Front": leftFrontleg,
      "Left Back": leftBackleg,
      "Left Heel": leftHeel,
    },
  };
  const [currentBodypart, setCurrentBodypart] = useState([
    "Body",
    "Body Front",
  ]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [canvasData, setCanvasData] = useState({});
  const [canvasData1, setCanvasData1] = useState({});
  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [paths, setPaths] = useState([]); // Store drawn paths
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const mainButtons = Object.keys(bodyMapConst);
  const subButtons = bodyMapConst[currentBodypart[0]];
  const isTwoButtons = Object.keys(subButtons).length === 2;
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 200;
    canvas.height = 200;
    ctxRef.current = canvas.getContext("2d");
    ctxRef.current.lineCap = "round";
    ctxRef.current.strokeStyle = "black";
    ctxRef.current.lineWidth = 2;

    if (canvasData[currentBodypart[1]]) {
      const img = new Image();
      img.src = canvasData[currentBodypart[1]];
      img.onload = () => ctxRef.current.drawImage(img, 0, 0);
    } else {
      ctxRef.current.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [currentBodypart, canvasData]);
  const saveCanvasData = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    setCanvasData((prevData) => ({
      ...prevData,
      [currentBodypart[1]]: dataURL,
    }));
    setCanvasData1((prevData) => ({
      ...prevData,
      [currentBodypart[1]]: dataURL,
    }));
  };
  const handleMouseDown = (e) => {
    if (!isDrawing && !isErasing) return;
    setIsMouseDown(true);

    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    ctxRef.current.beginPath();
    ctxRef.current.moveTo(offsetX, offsetY);

    if (isDrawing) {
      setPaths((prevPaths) => [
        ...prevPaths,
        { x: offsetX, y: offsetY, path: [] },
      ]);
    }
    setCursorPosition({ x: offsetX, y: offsetY }); // Set initial cursor position
  };
  const handleMouseUp = () => {
    setIsMouseDown(false);
    // saveCanvasData();
    // if (isDrawing) {
    //   setPaths((prevPaths) => {
    //     const updatedPaths = [...prevPaths];
    //     const lastPath = updatedPaths.pop();
    //     lastPath.path.push(lastPath);
    //     return updatedPaths;
    //   });
    // }
  };
  const handleMouseMove = (e) => {
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    if ((isDrawing || isErasing) && isMouseDown) {
      setCursorPosition({ x: offsetX, y: offsetY }); // Update cursor position

      if (isDrawing) {
        const lastPath = paths[paths.length - 1];
        lastPath.path.push({ x: offsetX, y: offsetY });

        ctxRef.current.lineTo(offsetX, offsetY);
        ctxRef.current.stroke();
      } else if (isErasing) {
        ctxRef.current.clearRect(offsetX - 10, offsetY - 10, 20, 20);
      }
    }
  };
  const handleDrawMode = () => {
    setIsDrawing(true);
    setIsErasing(false);
  };

  const handleEraseMode = () => {
    setIsErasing(true);
    setIsDrawing(false);
  };
  const handleImageSelect = (image, text) => {
    setCurrentBodypart([currentBodypart[0], text]); // Update current body part state
    setSelectedImages((prevImages) => {
      if (!prevImages.some((img) => img?.image === image)) {
        return [...prevImages, { image, text }]; // Add image and text if not already selected
      }
      return prevImages;
    });
    setIsDrawing(false);
    setIsErasing(false);
  };
  const handleSaveClick = () => {
    //
    if (isDrawing && paths.length > 0) {
      setSelectedImages1((prevImages) => {
        return [...prevImages, selectedImages[selectedImages?.length - 1]];
      });
      saveCanvasData();
    }
    setPaths([]);
    setCanvasData({});
    setSelectedImages([]);
    setIsDrawing(false);
    setIsErasing(false);
  };
  const handleImageRemove = (image) => {
    // Remove the image from selected images
    setSelectedImages1((prevImages) =>
      prevImages.filter((img) => img.image !== image)
    );

    // Also clear the corresponding canvas data (sketch) for this image
    setCanvasData1((prevData) => {
      const updatedData = { ...prevData };
      const imageKey = selectedImages1.find((img) => img.image === image)?.text;
      if (imageKey) {
        delete updatedData[imageKey];
      }
      return updatedData;
    });

    // Clear the canvas only if the removed image matches the currently displayed one
    if (canvasRef.current && currentBodypart[1] === image) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear entire canvas
    }
  };

  useEffect(() => {
    const selctimage = selectedImages1.map((item) => item?.image);
    const FileName = selctimage.map((item) => item?.split(".").pop());
    setBodyData1(FileName);
    const body_parts1 = selectedImages1.map((item) => item?.text);
    setBodyData(body_parts1);
    const canvasimages = Object.values(canvasData1);
    console.log('canvasimages=================================>', canvasimages);
    convertImagesToBase64(selctimage)
      .then((base64Array) => {
        let result = "";
        base64Array?.map((item, index) => {
          result = result + item + "," + canvasimages[index] + ",";
        });
        setBodyData2(result);
      })
      .catch((error) => {
        console.error("Error converting images to Base64:", error);
      });
  }, [selectedImages1, setBodyData, setBodyData1, setBodyData2]);

  return (
    <Box sx={{ pb: "20px" }}>
      <Box sx={{ pt: "20px", pb: "0px", borderBottom: "1px solid #27998933" }}>
        {/* Main buttons */}
        <Box display="flex" marginBottom={2} ml={2}>
          {mainButtons.map((part) => (
            <TabButton
              key={part}
              data={{
                id: part,
                text: part,
              }}
              isActive={currentBodypart[0] === part}
              onClick={() =>
                setCurrentBodypart([part, Object.keys(bodyMapConst[part])[0]])
              }
            />
          ))}
        </Box>

        {/* Sub-buttons for the selected main body part */}
        <Box
          display="grid"
          gap={isTwoButtons ? 26 : 2}
          marginBottom={6}
          sx={{
            gridTemplateColumns: `repeat(${
              Object.keys(subButtons).length
            }, 1fr)`,
            mr: "23px",
            ml: 2,
          }}
        >
          {Object.keys(subButtons).map((subPart) => (
            <Button
              key={subPart}
              onClick={() => handleImageSelect(subButtons[subPart], subPart)}
              sx={{
                backgroundColor: selectedImages?.some(
                  (img) => img?.image === subButtons[subPart]
                )
                  ? "#279989"
                  : "#DFF1F8",
                color: selectedImages?.some(
                  (img) => img?.image === subButtons[subPart]
                )
                  ? "#fff"
                  : "#1E303799",
                textTransform: "none",
                fontSize: "12px",
                padding: "6px 10px",
                marginTop: "10px ",
                borderRadius: "8px",
                mx: "10px",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#279989",
                },
              }}
            >
              {subPart}
            </Button>
          ))}
        </Box>

        {/* Display selected image */}
        <Box
          sx={{
            position: "relative",
            width: "200px",
            height: "200px",
            left: "34%",
          }}
        >
          <img
            src={subButtons[currentBodypart[1]] || "placeholder.png"}
            alt={`${currentBodypart[0]} ${currentBodypart[1]}`}
            style={{ width: "100%", height: "100%" }}
          />
          <canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              cursor: "none",
              pointerEvents: isDrawing || isErasing ? "auto" : "none",
            }}
          />
          {isDrawing && (
            <ModeEditIcon
              style={{
                position: "absolute",
                left: cursorPosition.x,
                top: cursorPosition.y,
                transform: "translate(-50%, -50%)",
                pointerEvents: "none", 
                fontSize: "25px", 
                color: "black", 
              }}
            />
          )}
        </Box>

        {/* Save button */}
        <Box
          sx={{ display: "flex", justifyContent: "space-between", my: "18px" }}
        >
          <Box sx={{ display: "flex", gap: 4, marginTop: "15px" }}>
            <Button
              onClick={handleDrawMode}
              sx={{
                mr: "10px",
                cursor: "pointer",
                backgroundColor: "#DFF1F8",
                ml: "20px",
              }}
            >
              <CreateIcon />
            </Button>
            <Button
              onClick={handleEraseMode}
              sx={{ mr: "10px", backgroundColor: "#DFF1F8" }}
            >
              <EditNoteIcon />
            </Button>
          </Box>
          <Button
            variant="customsave"
            onClick={handleSaveClick}
            disabled={paths.length === 0}
            color="primary"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              width: "80px",
              height: "40px",
              mr: "20px",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      {/* Display selected images as buttons (labels) */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {selectedImages1.map(({ image, text }, index) => (
          <Box key={index} sx={{ position: "relative" }}>
            <Button
              sx={{
                backgroundColor: "#DFF1F8",
                color: "#279989",
                fontSize: "12px",
                padding: "6px 16px",
                my: "15px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                ml: 2,
              }}
              onClick={() => handleImageRemove(image)}
            >
              {text}
              <HighlightOffIcon sx={{ ml: 1.5, fontSize: "18px", mb: 1 }} />
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default BodyMap;
