// src/styles/useStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
       width:'100%',
  },
  // content: {
  //   marginTop: '15px', // Adjust based on header height
  //   overflowY: 'auto',
  //   height: 'calc(100vh - 60px)', // Fill remaining height
   
  // },
  medicationHeader:{
    padding: '15px',
    borderRadius: "10px",
    height: "calc(100vh - 220px)",
    boxShadow: "0px 0px 10px 0px #27998933",
    width: "100%",

    display: 'flex'
  },
  medicationsidebar:{
    width: '20%',
    background: "#F0FCFA",
    borderRadius: "8px",
    padding: "10px",
    height: "100%",
    overflowY: "scroll", // Allow scrolling
    scrollbarWidth: "none", // For Firefox
    '&::-webkit-scrollbar': { // For Chrome, Safari, and Edge
      display: 'none',
    },
  },
  medicationRightSideContent:{
    width: '80%',
    paddingLeft: '30px',
    maxHeight: "calc(100vh - 220px)", // Sets max height for scrolling
    overflowY: "auto", // Enable vertical scrolling for content
    scrollbarWidth: "none", // For Firefox
    '&::-webkit-scrollbar': { // For Chrome, Safari, and Edge
      display: 'none',
    }
  },
  sessionLabel: { // Corrected property name
    display: 'none !important' // Ensure the correct syntax
  },
  medicationTable:{
    fontSize: "14px !important", // Apply !important to the font size
    width: "100% !important", // Ensure the table width is 100%
    borderCollapse: "collapse !important", // Example of applying !important for border collapse
    border: "2px solid #ffff !important",
    boxShadow: "0px 0px 10px 0px #2799894D",
    // Apply border style with !important
  },
  medicationTableHeaderCell: {
    fontSize:'13px !important',
    padding:'1px !important',
    color: "white !important", 
    fontWeight: "400 !important", 
    textAlign: "center !important", 
  },
}));

export default useStyles;
