import React, { useState } from "react";
import { Box } from "@mui/material";
import RosterDashboardHeader from "./RosterDashboardHeader";
import RosterTable from "./RosterTable";
//import { useState } from "react";

export default function RosterDashboard() {
  const [view, setView] = useState(); // Initialize with today's date
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <RosterDashboardHeader setView={setView} />
      <RosterTable view={view} />
    </Box>
  );
}

//export default RosterDashboard;
