import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";

function MARChart({ clientMainData }) {
  const [userName, setUserName] = useState("");
  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);
  return (
    <Grid container>
      <Box sx={{ width: "100%" }}>
        <ClientHeader head={`MAR Chart for ${userName}`} />
      </Box>
    </Grid>
  );
}

export default MARChart;
