export const prefixs = [
  {
    id: 1,
    text: "Mr.",
  },
  {
    id: 2,
    text: "Mrs.",
  },
  {
    id: 3,
    text: "Master.",
  },
  {
    id: 4,
    text: "Miss.",
  },
  {
    id: 5,
    text: "Ms",
  },
  {
    id: 6,
    text: "Sir",
  },
  {
    id: 7,
    text: "Lady",
  },
  {
    id: 8,
    text: "Lord",
  },
  {
    id: 9,
    text: "Dame",
  },
  {
    id: 10,
    text: "Dr.",
  },
  {
    id: 11,
    text: "Prof.",
  },
];

export const specialists = [
  {
    id: 1,
    text: "Care Assistant",
  },
  {
    id: 2,
    text: "Senior Carer",
  },
  {
    id: 3,
    text: "Nurse",
  },
];

export const risklevel = [
  {
    id: 1,
    text: "High",
  },
  {
    id: 2,
    text: "Moderate",
  },
  {
    id: 3,
    text: "Low",
  },
];
export const maritalStatus = [
  {
    id: 1,
    text: "Single",
  },
  {
    id: 2,
    text: "Married/Civil Partner",
  },
  {
    id: 3,
    text: "Divorced/Person whose Civil Partnership has been dissolved",
  },
  {
    id: 4,
    text: "Widowed/Surviving Civil Partner",
  },
  {
    id: 5,
    text: "Separated",
  },
  {
    id: 6,
    text: "Not disclosed",
  },
];

export const invoiceFrequency = [
  {
    id: 1,
    text: "Monthly",
  },
  {
    id: 2,
    text: "Quarterly",
  },
  {
    id: 3,
    text: "Yearly",
  },
];
export const ChooseSessions = [
  {
    id: 1,
    text: "Morning",
  },
  {
    id: 2,
    text: "Afternoon",
  },
  {
    id: 3,
    text: "Tea",
  },
  {
    id: 4,
    text: "Dinner",
  },
  {
    id: 5,
    text: "Night",
  },
];

export const RosterViews = [
  {
    id: 1,
    text: "Carer View",
  },
  {
    id: 2,
    text: "Client View",
  },
];

export const RosterRuns = [
  {
    id: 1,
    text: "Run1",
  },
  {
    id: 2,
    text: "Run2",
  },
  {
    id: 3,
    text: "Run3",
  },
  {
    id: 4,
    text: "Run4",
  },
  {
    id: 5,
    text: "Run5",
  },
];

export const RosterTeams = [
  {
    id: 1,
    text: "Team1",
  },
  {
    id: 2,
    text: "Team2",
  },
  {
    id: 3,
    text: "Team3",
  },
  {
    id: 4,
    text: "Team4",
  },
  {
    id: 5,
    text: "Team5",
  },
];
export const medicationPrnTime = [
  {
    id: 1,
    text: "Hours",
  },
  {
    id: 2,
    text: "Minutes",
  },
  {
    id: 3,
    text: "Days",
  },
];

export const BlisterSesiions = [
  {
    id: 1,
    text: "Morning",
  },
  {
    id: 2,
    text: "Afternoon",
  },
  {
    id: 3,
    text: "Tea",
  },
  {
    id: 4,
    text: "Dinner",
  },
  {
    id: 5,
    text: "Night",
  },
];