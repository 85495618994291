import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../themes/Theme";
import Dropdown from "../reusableComponents/Dropdown";
import DragAndDrop from "../../assets/images/roster/draganddrop.svg";
import Calendar from "../reusableComponents/Calendar";
import TableDragIcon from "../../assets/images/roster/tabledragicon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getCookie } from "../../utils/utils";
import dayjs from "dayjs";

export default function AddVisits({ setOpenAddVisitsForm, selectedRun }) {
  const [assignedVisits, setAssignedVisits] = useState([]);
  const [originalRunsList, setOriginaRunsList] = useState([]);
  const [visitsList, setVisitsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [unassignedVisits, setUnassignedVisits] = useState(visitsList);
  const [selectedRunName, setSelectedRunName] = useState(selectedRun);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setAssignedVisits([]);
  };

  const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD"); // Converts to dd-mm-yyyy

  // Handle drag end, determining where the visit was dropped
  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If dropped outside of the list or in the same position
    if (
      !destination ||
      (destination.index === source.index &&
        destination.droppableId === source.droppableId)
    ) {
      return;
    }

    // If visit is dragged within the same list (Assigned or Unassigned)
    if (source.droppableId === destination.droppableId) {
      const visits =
        source.droppableId === "assigned"
          ? [...assignedVisits]
          : [...unassignedVisits];
      const [removed] = visits.splice(source.index, 1);
      visits.splice(destination.index, 0, removed);

      if (source.droppableId === "assigned") {
        setAssignedVisits(visits);
      } else {
        setUnassignedVisits(visits);
      }
    }
    // If visit is moved between lists (Assigned -> Unassigned or vice versa)
    else {
      let sourceVisits =
        source.droppableId === "assigned"
          ? [...assignedVisits]
          : [...unassignedVisits];
      let destinationVisits =
        destination.droppableId === "assigned"
          ? [...assignedVisits]
          : [...unassignedVisits];

      const [removed] = sourceVisits.splice(source.index, 1);
      destinationVisits.splice(destination.index, 0, removed);

      if (source.droppableId === "assigned") {
        setAssignedVisits(sourceVisits);
      } else {
        setUnassignedVisits(sourceVisits);
      }

      if (destination.droppableId === "assigned") {
        setAssignedVisits(destinationVisits);
      } else {
        setUnassignedVisits(destinationVisits);
      }
    }
  };

  const fetchRunsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-runs`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        const modifiedRunsList = data.data.map((each) => ({
          ...each,
          id: each.id,
          text: each.run_name,
          value: each.run_name,
        }));

        setOriginaRunsList(modifiedRunsList);
      } else {
        console.log("Failed to fetch runs");
        setOriginaRunsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchRunsAPI();
  }, []);

  const fetchVisitsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-open-visits?visit_date=${formattedDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        setVisitsList(data.data);
      } else {
        console.log("Failed to fetch runs");
        setVisitsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchVisitsAPI();
  }, [selectedDate]);

  useEffect(() => {
    const assigned = visitsList.filter(
      (visit) => visit.runid === selectedRunName.id
    );
    const unassigned = visitsList.filter((visit) => visit.runid === "");

    setAssignedVisits(assigned);
    setUnassignedVisits(unassigned);
  }, [visitsList, selectedRunName]); // runs when visitsList changes or selectedrun changes

  const postAssignedVisits = async () => {
    try {
      const body = {
        run_id: selectedRunName.id,
        visit_details_id: assignedVisits.map((visit) => visit.id),
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-visit-details-ids`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setAssignedVisits([]);
      } else {
        console.log("Failed to set assigned visits");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const preFilledValue = originalRunsList.find(
    (option) => option.run_name === selectedRun?.run_name
  );

  const handleClose = () => setOpenAddVisitsForm(false);

  const handleSave = () => {
    postAssignedVisits();
    setOpenAddVisitsForm(false);
  };

  const handleRunChange = (value) => {
    setSelectedRunName(value);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ThemeProvider theme={EditTheme}>
        {/* Heading */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            textAlign: "left",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: "-20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              alignItems: "flex-start",
              borderRadius: "10px",
            }}
          >
            <Typography variant="h4">Assign Runs</Typography>{" "}
            <Typography
              variant="body1"
              sx={{ marginLeft: "-20px", marginTop: "-7px" }}
            >
              Utilise runs to consolidate visits and make the most of your
              schedule
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlinedSecondary"
              sx={{ marginLeft: "30px" }}
              onClick={() => setOpenAddVisitsForm(false)}
            >
              Back
            </Button>
          </Box>
        </Box>

        {/* Outer Container */}
        <Box
          sx={{
            border: "1px solid #27998966",
            borderRadius: "10px",
            marginTop: "10px",
            height: "calc(100vh - 220px)",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "10px 0px",
          }}
        >
          {/* Left Container */}
          <Box
            sx={{
              borderRight: "1px solid #27998966",
              padding: "5px 10px 10px 10px",
              width: "35%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              textAlign: "left",
              alignItems: "flex-start",
            }}
          >
            {/* Sticky Header Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff", // to avoid content showing behind it
                zIndex: 2, // ensures the header is above scrolling content
                paddingRight: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ marginBottom: "-10px", marginRight: "10px" }}
              >
                Select Run:
              </Typography>
              <Box sx={{ flex: 1 }}>
                <Dropdown
                  list={originalRunsList}
                  nolabel
                  preFilledvalue={preFilledValue ? preFilledValue.run_name : ""}
                  onDropdownSelect={handleRunChange}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Box>

            {/* Scrollable Content Section */}
            <Box
              sx={{
                overflow: "auto",
                flex: 1,
                paddingTop: "10px",
                paddingRight: "10px", // Adds space between content and scrollbar
                boxSizing: "border-box", // Ensures padding is within the width
                width: "100%",
              }}
            >
              <Grid container gap={5}>
                <Grid item xs={12}>
                  <Droppable droppableId="assigned">
                    {(provided) => (
                      <div
                        className="task-container"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          border: "1px solid #27998966",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {assignedVisits.length === 0 ? (
                          <Box>
                            <img
                              src={DragAndDrop}
                              alt="drag and drop"
                              style={{ width: "58%" }}
                            />
                            <Grid
                              item
                              xs={12}
                              sx={{
                                marginTop: "20px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography variant="body2">***Note</Typography>
                              <Typography variant="body2">
                                Please drag and drop your open visits to
                                complete a run
                              </Typography>
                            </Grid>
                          </Box>
                        ) : (
                          assignedVisits.map((visit, index) => (
                            <Draggable
                              key={visit.id}
                              draggableId={String(visit.id)}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="task-item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Box
                                    sx={{
                                      border: "1px solid #279989",
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      backgroundColor: "#EEFEFA",
                                      borderRadius: "10px",
                                      marginBottom: "50px",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#279989",
                                          width: "50%",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {visit.client_name}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989" }}
                                      >
                                        {visit.visit_time}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989" }}
                                      >
                                        {visit.location}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#279989" }}
                                      >
                                        {visit.number_of_carers_required}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>

                <Grid item xs={12}>
                  <Button variant="customcancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="customsave" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Right Container */}
          <Box
            sx={{
              width: "65%",
              position: "relative",
              marginBottom: "10px",
            }}
          >
            {/* Header */}
            <Typography
              variant="h5"
              sx={{
                marginTop: "15px",
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translate(-50%, 15%)",
              }}
            >
              Open Visits
            </Typography>

            {/* Header Calendar Content */}
            <Box>
              <Grid container>
                <Grid item xs={4.5} sx={{ paddingLeft: "50px" }}>
                  <Box sx={{ width: "70%", marginTop: "5px" }}>
                    <Calendar
                      nolabel
                      onDateChange={handleDateChange}
                      preFilledvalue={selectedDate}
                    />
                  </Box>
                </Grid>
                <Grid item xs={7.5}></Grid>

                {/* Table Header */}
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "50px", marginRight: "25px" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      backgroundColor: "#279989",
                      padding: "8px 16px",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "white", width: "30%", textAlign: "left" }}
                    >
                      Client Name
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "white", width: "15%" }}
                    >
                      Location
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "white", width: "15%" }}
                    >
                      Session
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "white", width: "25%" }}
                    >
                      Visit Time
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "white", width: "15%" }}
                    >
                      No. of Carers
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Scrollable Body Cards */}
            <Box
              sx={{
                maxHeight: "calc(100vh - 480px)",
                overflowY: "scroll", // Enable vertical scrolling
                marginLeft: "25px",
                marginRight: "10px",
                marginTop: "20px",
                paddingRight: "10px",
              }}
            >
              <Droppable droppableId="unassigned">
                {(provided) => (
                  <div
                    className="task-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      minHeight: "calc(100vh - 480px)",
                    }}
                  >
                    {unassignedVisits.map((visit, index) => (
                      <Draggable
                        key={visit.id}
                        draggableId={String(visit.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="task-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Grid container key={index}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-end",
                                  marginBottom: "20px",
                                }}
                              >
                                <Box
                                  sx={{
                                    marginRight: "10px",
                                    marginBottom: "9px",
                                  }}
                                >
                                  <img
                                    src={TableDragIcon}
                                    alt="TableDragIcon"
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    width: "100%",
                                    border: "1px solid #279989",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "#EEFEFA",
                                    padding: "8px 16px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color: "#279989",
                                      width: "30%",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {visit.client_name}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#279989", width: "15%" }}
                                  >
                                    {visit.location}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#279989", width: "15%" }}
                                  >
                                    {visit.session}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#279989", width: "25%" }}
                                  >
                                    {visit.visit_time}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#279989", width: "15%" }}
                                  >
                                    {visit.number_of_carers_required}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </DragDropContext>
  );
}
