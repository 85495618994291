import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  Grid,
  Switch,
  Typography,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { SettingsSideBar } from "../settings/SettingsSideBar";
import { EditTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import QrCodeIcon from "@mui/icons-material/QrCode";

const ClientSetting = ({ clientId, clientMainData, setTabId, activeTabID }) => {
  const [isNotificationEnabled, setNotificationEnabled] = useState(false);
  const [isGeoLocationEnabled, setGeoLocationEnabled] = useState(false);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  const handleNotificationToggle = (event) => {
    setNotificationEnabled(event.target.checked);
  };

  const handleGeoLocationToggle = (event) => {
    setGeoLocationEnabled(event.target.checked);
  };
  return (
    <ThemeProvider theme={EditTheme}>
      <Grid container direction="row" alignItems="top">
        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", height: "100%" }}>
              <SettingsSideBar
                clientId={clientId}
                clientMainData={clientMainData}
                setTabId={setTabId}
                activeTabID={activeTabID}
              />
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <ClientHeader
                  head={`${clientName}'s Settings`}
                  isHideButton={false}
                />
              </Box>
              <Box>
                {/* Notification Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName}'s Notification
                      </Typography>
                      <Typography variant="body1">
                        Notifications of late or missing visits for Nolan
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isNotificationEnabled}
                        onChange={handleNotificationToggle}
                        variant="ios" // Apply the custom iOS variant here
                        inputProps={{ "aria-label": "notification toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Geo Location Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        Enable Geo Location Check-in and check-out
                      </Typography>
                      <Typography variant="body1">
                        Confirmation of carers location for check-in and
                        check-out to a visit
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isGeoLocationEnabled}
                        onChange={handleGeoLocationToggle}
                        inputProps={{ "aria-label": "geo location toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* QR Code Section */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        QR Code check-in and check-out
                      </Typography>
                      <Typography variant="body1">
                        Check-in and check-out for a visit by scanning the QR
                        code
                      </Typography>
                      <Box sx={{ margin: "10px 20px" }}>
                        <Button
                          type="submit"
                          variant="outlinedSecondary"
                          sx={{ marginRight: "20px" }}
                        >
                          Regenerate QR Code
                        </Button>
                        <Button variant="outlinedSecondary">
                          Print QR Code
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <QrCodeIcon
                          fontSize="large"
                          sx={{
                            height: "100px",
                            width: "100px",
                            color: "#269989",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </div>
          </div>
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default ClientSetting;
