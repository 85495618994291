import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import AddMedication from "./AddMedication ";
import MARChart from "./MARChart";
import ManageMedication from "./ManageMedication";
import useStyles from "../styles/useStyles";

const Tab = ({ each, isActiveTab, onClick }) => {
  const [isHover, setHover] = useState(false);
  return (
    <Button
      onClick={onClick}
      key={each.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="outlinedSecondary"
      style={{
        border: isActiveTab
          ? "2px solid #279989"
          : isHover
          ? "2px solid #279989"
          : "2px solid rgba(39, 153, 137, 0.30)",
        color: isActiveTab ? "#279989" : isHover ? "#279989" : "#707171",
        background: "white",
        width: "100%",
        margin: "5px 0px",
        display: "flex",
        justifyContent: "start",
      }}
    >
      <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.5L6 7L1 12.5"
          stroke={isActiveTab || isHover ? "#279989" : "#969999"}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span style={{ margin: "auto" }}> {each.text}</span>
    </Button>
  );
};

const Medication = ({ clientMainData, clientId }) => {
  const [activeTabId, setActiveTabId] = useState('section1');
  const tabsListConstants = [
    {
      id: "section1",
      text: "Add Medication",
      component: (
        <AddMedication clientMainData={clientMainData} clientId={clientId}  setActiveTabId={setActiveTabId} />
      ),
    },
    {
      id: "section2",
      text: "MAR Chart",
      component: <MARChart clientMainData={clientMainData} />,
    },
    {
      id: "section3",
      text: "Manage Medication",
      component: <ManageMedication clientMainData={clientMainData} clientId={clientId} />,
    },
  ];

  const classes = useStyles();
 

  const handleTabClick = (id) => {
    setActiveTabId(id);
  };

  return (
    <Grid container direction="row" alignItems="top">
      <Box className={classes.medicationHeader}>
        <Box className={classes.medicationsidebar}>
          <ClientSideBarHeader clientMainData={clientMainData} />
          <Grid fullWidth>
            {tabsListConstants.map((each) => (
              <Tab
                each={each}
                isActiveTab={activeTabId === each.id}
                onClick={() => handleTabClick(each.id)}
              />
            ))}
          </Grid>
        </Box>
        <Box className={classes.medicationRightSideContent}>
          {tabsListConstants.find((tab) => tab.id === activeTabId)?.component}
        </Box>
      </Box>
    </Grid>
  );
};

export default Medication;
