import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  FormLabel,
  Button,
  //TextField,
} from "@mui/material";
import { ClientSideBarHeader } from "../../clientReusableComponents/ClientSideBarHeader";
import EditIcon from "@mui/icons-material/Edit";

const VisitDetails = ({ visit, clientMainData, setEdit }) => {
  return (
    <Box>
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            width: "40%",
            height: "100%",
            borderRight: "1px solid #279989",
          }}
        >
          <Box
            sx={{
              background: "#F0FCFA",
              borderRadius: "8px",
              padding: "10px",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <ClientSideBarHeader clientMainData={clientMainData} />
          </Box>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            width: "80%",
            paddingLeft: "25px",
            paddingRight: 5,
            position: "relative",
          }}
        >
          <Box sx={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<EditIcon />}
              onClick={() => setEdit(true)} // Correct function to update the state
              sx={{ margin: 0, height: "35px" }}
            >
              Edit
            </Button>
          </Box>
          <Grid
            container
            direction="row"
            alignItems="top"
            sx={{
              marginTop: "20px",
              "& > .MuiGrid-item": {
                marginTop: "20px", // Apply marginTop to all grid items
              },
            }}
          >
            <Grid item xs={5} lg={5}>
              <Typography variant="body2" color="#6C7479">
                Visit Date
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography variant="body4">:</Typography>
            </Grid>
            <Grid item xs={6.5} lg={6.5}>
              <Typography variant="body4">{visit.visit_date}</Typography>
            </Grid>

            <Grid item xs={5} lg={5}>
              <Typography variant="body2" color="#6C7479">
                Scheduled Time
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography variant="bocy4">:</Typography>
            </Grid>
            <Grid item xs={6.5} lg={6.5}>
              <Typography variant="body4">
                {visit.start_time} {" : "} {visit.end_time}
              </Typography>
            </Grid>

            <Grid item xs={5} lg={5}>
              <Typography variant="body2" color="#6C7479">
                Number of Carers
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography variant="bocy4">:</Typography>
            </Grid>
            <Grid item xs={6.5} lg={6.5}>
              <Typography variant="body4">
                {visit.number_of_carers_required}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
};

export default VisitDetails;
