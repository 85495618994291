import React, { useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import ToDoList from "../../assets/images/logs/todolist.svg";
import FirstAid from "../../assets/images/logs/firstaidkit.svg";

export default function LogCards({ visit, onClick, isSelected }) {
  const getInitials = (name) => {
    const nameParts = name.trim().split(" "); // Split the name by spaces
    const firstInitial = nameParts[0] ? nameParts[0][0] : ""; // First letter of the first name
    const lastInitial =
      nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : ""; // First letter of the last name
    return firstInitial + lastInitial;
  };

  const [isHovered, setIsHovered] = useState(false); // Local hover state

  return (
    <Box
      onClick={onClick}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        borderRadius: "15px",
        padding: "10px",
        border:
          isSelected || isHovered
            ? visit.visitStatus === "Completed"
              ? "3px solid #279989"
              : visit.visitStatus === "In Progress"
              ? "3px solid #0366B3"
              : visit.visitStatus === "Action Required"
              ? "3px solid #FF8080"
              : visit.visitStatus === "Follow Up"
              ? "3px solid #E375E6"
              : "3px solid #279989"
            : "3px solid white",
      }}
    >
      {/* Outer Box */}
      <Box
        onMouseEnter={() => setIsHovered(true)} // Hover state on enter
        onMouseLeave={() => setIsHovered(false)} // Hover state off on leave
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          //marginBottom: "10px",
          backgroundColor:
            visit.visitStatus === "Completed"
              ? "#C6EFD2"
              : visit.visitStatus === "In Progress"
              ? "#E7F6FF"
              : visit.visitStatus === "Action Required"
              ? "#FFEDED"
              : visit.visitStatus === "Follow Up"
              ? "#FAF1FC"
              : "#F0FCFA",
          borderRadius: "10px",
          padding: "10px",
          cursor: "pointer",
        }}
      >
        {/* Top Box */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: "50px",
              height: "50px",
              backgroundColor: "#279989",
              marginRight: "10px",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Lora",
                color: "white",
                padding: "0px",
                margin: "0px",
              }}
            >
              {getInitials(visit.clientName)}
            </Typography>
          </Avatar>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              textWrap: "nowrap",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "black",
                }}
              >
                {visit.clientName}
              </Typography>
              {visit.visitStatus !== "In Progress" ? (
                <Typography
                  variant="h6"
                  sx={{
                    textWrap: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "black",
                    borderRadius: "5px",
                    padding: "2px 5px",
                    backgroundColor:
                      visit.visitStatus === "Completed"
                        ? "#F2FFF6"
                        : visit.visitStatus === "Action Required"
                        ? "#FCDDDD"
                        : visit.visitStatus === "Follow Up"
                        ? "#F7DEFD"
                        : "",
                  }}
                >
                  60 min / 60 min
                </Typography>
              ) : null}
            </Box>
            <Typography
              variant="h6"
              sx={{
                textWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
              }}
            >
              Carers: {visit.carers[0]}, {visit.carers[1]}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
              }}
            >
              Run: {visit.runName}
            </Typography>
          </Box>
        </Box>

        {/* Bottom Box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            paddingTop: "10px",
            overflow: "hidden",
            borderTop:
              visit.visitStatus === "Completed"
                ? "1px solid #2799894D"
                : visit.visitStatus === "In Progress"
                ? "1px solid #BCDBEE"
                : visit.visitStatus === "Action Required"
                ? "1px solid #FDACAC"
                : visit.visitStatus === "Follow Up"
                ? "1px solid #CF75E6"
                : "1px solid #F0FCFA",
          }}
        >
          {/* Left Bottom Box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* ToDo Count Box */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={ToDoList}
                alt="ToDoList"
                style={{ mixBlendMode: "multiply" }}
              />

              <Typography
                variant="body1"
                sx={{
                  padding: "0px 3px",
                  color: "black",
                }}
              >
                : {visit.completedTodos}
              </Typography>
            </Box>
            {/* Medication Count Box*/}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={FirstAid}
                alt="Medication"
                style={{ mixBlendMode: "multiply", marginTop: "-2px" }}
              />

              <Typography
                variant="body1"
                sx={{
                  padding: "0px 3px",
                  color: "black",
                }}
              >
                : {visit.completedMedications}
              </Typography>
            </Box>
          </Box>
          {/* Right Bottom Box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                padding: "0px 3px",
                color: "black",
              }}
            >
              {visit.visitDate}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                backgroundColor:
                  visit.visitStatus === "Completed"
                    ? "#279989"
                    : visit.visitStatus === "In Progress"
                    ? "#56ACE0"
                    : visit.visitStatus === "Action Required"
                    ? "#FF8080"
                    : visit.visitStatus === "Follow Up"
                    ? "#CF75E6"
                    : "transparent",
                borderRadius: "5px",
                color: "white",
              }}
            >
              {visit.visitStatus}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
