import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import RosterVisitCard from "./RosterVisitCard";
import RosterVisitCardDetails from "./RosterVisitCardDetails";
import UserCard from "./RosterUserCard";
import ClientCard from "./RosterClientCard";
import RosterAssignCarerDialog from "./RosterAssignCarerDialog";

const visitSlots = Array.from({ length: 48 }); // Array for half-hour intervals over 24 hours

export default function RosterTable({ view }) {
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [hoveredUserId, setHoveredUserId] = useState(null);
  const [focusedCardId, setFocusedCardId] = useState(null);
  const [openAssignCarerDialog, setOpenAssignCarerDialog] = useState(false);

  const [showAbove, setShowAbove] = useState(false);
  const cardRefs = useRef(new Map()); // Map to hold refs for each card

  const handleMouseEnter = (visitId, userId) => {
    const cardRef = cardRefs.current.get(visitId, userId);

    if (cardRef) {
      const cardRect = cardRef.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Check if there's enough space below the card
      setShowAbove(windowHeight - cardRect.bottom < 300);
    }
    if (!focusedCardId) {
      setHoveredCardId(visitId); // Track the specific user id for hover
    }

    if (userId) {
      setHoveredUserId(userId); // Track the specific user id for hover
    }
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
    setHoveredUserId(null); // Reset on mouse leave
    setFocusedCardId(null);
  };

  const handlecard = (visitId) => {
    setHoveredCardId(null);
    setHoveredUserId(null); // Reset on mouse leave
    setFocusedCardId(visitId);
  };

  // useEffect(() => {
  //   console.log(hoveredCardId);
  // }, [hoveredCardId]);
  const [visits, setVisits] = useState([
    {
      id: 1,
      startTime: "07:11",
      endTime: "11:40",
      userid: [2],
      clientid: 1,
      status: "Scheduled",
      userName: ["Harish Thallada ", "Vamsi Chevva"],
      clientName: "Samitha Nikam",
      maleCarers: 2,
      femaleCarers: 2,
      actualStartTime: "",
      actualEndTime: "",
      run: "Run2",
      runid: 2,
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 2,
      startTime: "10:00",
      endTime: "12:30",
      userid: [2, 4, 5],
      clientid: 2,
      status: "Completed",
      userName: ["Vamsi Kakani"],
      clientName: "Jishnu Sree",
      maleCarers: 1,
      femaleCarers: 1,
      actualStartTime: "10:20",
      actualEndTime: "12:40",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 3,
      startTime: "15:30",
      endTime: "17:00",
      userid: [10],
      clientid: 3,
      status: "Cancelled",
      userName: ["Basha"],
      clientName: "Shabana",
      maleCarers: 0,
      femaleCarers: 2,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "15",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 4,
      startTime: "07:14",
      endTime: "09:40",
      userid: [],
      clientid: 4,
      status: "Unscheduled",
      userName: [],
      clientName: "Mounika",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "Run1",
      runid: 1,
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 5,
      startTime: "10:00",
      endTime: "10:30",
      userid: [2, 5],
      clientid: 5,
      status: "Inprogress",
      userName: ["Sai Kumar"],
      clientName: "Ketana",
      maleCarers: 0,
      femaleCarers: 1,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "20",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 6,
      startTime: "15:30",
      endTime: "17:00",
      userid: [2],
      clientid: 3,
      status: "Notstarted",
      userName: ["Rohith"],
      clientName: "Sanjita Rohith Thapa",
      maleCarers: 1,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "Run2",
      runid: 2,
      travelTime: "10",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 7,
      startTime: "08:30",
      endTime: "10:40",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "Duplicate",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 8,
      startTime: "08:40",
      endTime: "10:50",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "ABCD",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 9,
      startTime: "12:00",
      endTime: "17:50",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "klmn",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 10,
      startTime: "10:30",
      endTime: "15:50",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "klmn",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 11,
      startTime: "14:30",
      endTime: "15:50",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "klmn",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "Run1",
      runid: 1,
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },
    {
      id: 12,
      startTime: "14:30",
      endTime: "15:50",
      userid: [],
      clientid: 12,
      status: "Unscheduled",
      userName: [],
      clientName: "klmn",
      maleCarers: 2,
      femaleCarers: 0,
      actualStartTime: "",
      actualEndTime: "",
      run: "",
      runid: "",
      travelTime: "",
      rowIndex: 0,
      clientRowIndex: 0,
    },

    // Add more events as needed
  ]);
  const [URowIndex, setURowIndex] = useState(0);
  const [step, setStep] = useState(0);
  const [Users, setUsers] = useState([
    {
      userid: 1,
      name: "Harish Kumar Thallada Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 2,
      name: "Vamshi Kakani",
      gender: "Female",
      hours: "30",
      drive: 1,
    },
    {
      userid: 3,
      name: "Chevva Vamshi",
      gender: "Female",
      hours: "30",
      drive: 1,
    },
    {
      userid: 4,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    { userid: 5, name: "Vamshi Kakani", gender: "Male", hours: "30", drive: 1 },
    {
      userid: 6,
      name: "Chevva Vamshi",
      gender: "Female",
      hours: "30",
      drive: 0,
    },
    {
      userid: 7,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 1,
    },
    { userid: 8, name: "Vamshi Kakani", gender: "Male", hours: "30", drive: 0 },
    {
      userid: 9,
      name: "Chevva Vamshi",
      gender: "Female",
      hours: "30",
      drive: 0,
    },
    {
      userid: 10,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 11,
      name: "Vamshi Kakani",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 444,
      name: "Chevva Vamshi",
      gender: "Male",
      hours: "30",
      drive: 1,
    },
    {
      userid: 12,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 22,
      name: "Vamshi Kakani",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 33,
      name: "Chevva Vamshi",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 13,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 23,
      name: "Vamshi Kakani",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 31,
      name: "Chevva Vamshi",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 16,
      name: "Harish Thallada",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 26,
      name: "Vamshi Kakani",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    {
      userid: 36,
      name: "Chevva Vamshi",
      gender: "Male",
      hours: "30",
      drive: 0,
    },
    // Add more users as needed
  ]);
  const [Clients, setClients] = useState([
    { clientid: 1, name: "Samitha Nikam", visits: "30" },
    { clientid: 2, name: "Jishnu Sree", visits: "30" },
    { clientid: 3, name: "Mounika Rao", visits: "30" },
    { clientid: 4, name: "Samitha Nikam", visits: "30" },
    { clientid: 5, name: "Jishnu Sree", visits: "30" },
    { clientid: 6, name: "Mounika Rao", visits: "30" },
    { clientid: 7, name: "Samitha Nikam", visits: "30" },
    { clientid: 8, name: "Jishnu Sree", visits: "30" },
    { clientid: 9, name: "Mounika Rao", visits: "30" },
    { clientid: 10, name: "Samitha Nikam", visits: "30" },
    { clientid: 11, name: "Jishnu Sree", visits: "30" },
    { clientid: 31, name: "Mounika Rao", visits: "30" },
    { clientid: 12, name: "Samitha Nikam", visits: "30" },
    { clientid: 22, name: "Jishnu Sree", visits: "30" },
    { clientid: 33, name: "Mounika Rao", visits: "30" },
    { clientid: 13, name: "Samitha Nikam", visits: "30" },
    { clientid: 23, name: "Jishnu Sree", visits: "30" },
    { clientid: 39, name: "Mounika Rao", visits: "30" },
    { clientid: 16, name: "Samitha Nikam", visits: "30" },
    { clientid: 26, name: "Jishnu Sree", visits: "30" },
    { clientid: 36, name: "Mounika Rao", visits: "30" },
    // Add more users as needed
  ]);
  const isOverlapping = (visit1, visit2) => {
    // Use actual times if they are available, otherwise fallback to scheduled times
    const visit1Start = visit1.actualStartTime || visit1.startTime;
    const visit1End = visit1.actualEndTime || visit1.endTime;
    const visit2Start = visit2.actualStartTime || visit2.startTime;
    const visit2End = visit2.actualEndTime || visit2.endTime;

    return visit1Start <= visit2End && visit2Start <= visit1End;
  };
  const calculateRunTimes = (visits) => {
    // Step 1: Group visits by `run`
    const runTimes = {};

    // Iterate over each visit to calculate the min start time and max end time per `run`
    visits.forEach((visit) => {
      const { run, startTime, endTime } = visit;

      // Initialize the group in `runTimes` if it doesn't exist
      if (!runTimes[run]) {
        runTimes[run] = { minStartTime: startTime, maxEndTime: endTime };
      } else {
        // Update min start time and max end time for this `run`
        runTimes[run].minStartTime = Math.min(
          runTimes[run].minStartTime,
          startTime
        );
        runTimes[run].maxEndTime = Math.max(runTimes[run].maxEndTime, endTime);
      }
    });

    // Step 2: Update each visit with the calculated `runStartTime` and `runEndTime`
    const updatedVisits = visits.map((visit) => {
      const { run } = visit;
      const { minStartTime, maxEndTime } = runTimes[run];

      // Add `runStartTime` and `runEndTime` to each visit
      return {
        ...visit,
        runStartTime: minStartTime,
        runEndTime: maxEndTime,
      };
    });

    return updatedVisits;
  };
  const updateRowIndexForVisit = () => {
    console.log("Step1");

    setVisits((prevVisits) => {
      const updatedVisits = []; // Array to store visits with updated rowIndex values

      // Process each visit sequentially
      prevVisits.forEach((currentVisit, index) => {
        let updatedVisit = { ...currentVisit }; // Start with the current visit

        // Only work with visits that have empty userids
        if (currentVisit.userid.length === 0 && !currentVisit.run) {
          let overlappingRowIndices = []; // Array to store the row indices of all overlapping visits

          // Calculate max row index of all previous overlapping visits
          updatedVisit.rowIndex = updatedVisits.reduce((maxRowIndex, visit) => {
            if (
              visit.userid.length === 0 &&
              !visit.run &&
              isOverlapping(visit, currentVisit)
            ) {
              overlappingRowIndices.push(visit.rowIndex); // Add to overlaps array
              let k = Math.max(maxRowIndex, visit.rowIndex + 1);
              if (k > URowIndex) {
                setURowIndex(k);
              }
              console.log("k", k);
              //console.log
              return k; // Calculate max row index + 1
            }

            return maxRowIndex;
          }, 0);

          console.log(
            "visit",
            currentVisit.id,
            "overlaps",
            overlappingRowIndices
          );
        } else if (!currentVisit.run) {
          // Initialize an object to store unique row indices for each user in the current visit
          const userRowIndexMap = {};

          // Loop through each userid in the current visit
          currentVisit.userid.forEach((userId) => {
            let userZ = 0; // Initialize a counter for this specific user

            // Calculate max row index for this specific user by checking overlaps in previous visits
            userZ = updatedVisits.reduce((maxRowIndex, visit) => {
              if (
                visit.userid.includes(userId) &&
                !visit.run &&
                isOverlapping(visit, currentVisit)
              ) {
                return Math.max(
                  maxRowIndex,
                  visit[`rowindex${userId}`] + 1 || 0
                ); // Check max row index + 1
              }
              return maxRowIndex;
            }, 0);

            // Store the row index for this user in the map
            userRowIndexMap[`rowindex${userId}`] = userZ;
          });

          // Update visit with dynamically created rowindex properties for each userid
          updatedVisit = { ...updatedVisit, ...userRowIndexMap };
        }

        // Add the updated visit to the array so the next visit can access it
        updatedVisits.push(updatedVisit);
      });

      return updatedVisits; // Update state with all visits processed sequentially
    });

    setStep(1); // Proceed to the next step
  };

  const updateClientRowIndexForVisit = () => {
    console.log("Step2");
    console.log("Uservisits", visits);
    setVisits((prevVisits) =>
      prevVisits.map((currentVisit, index) => {
        // Initialize row index counter for the client
        let z = 0;

        // Loop through previous visits up to the current index
        for (let i = 0; i < index; i++) {
          // Check if visit overlaps and has the same clientid
          if (prevVisits[i].clientid === currentVisit.clientid) {
            if (isOverlapping(prevVisits[i], currentVisit)) {
              z += 1;
              // console.log(
              //   "Client overlap count for clientID:",
              //   currentVisit.clientid,
              //   "Count:",
              //   z
              // );
            }
          }
        }

        // Update current visit with the calculated clientRowIndex based on overlap count
        return {
          ...currentVisit,
          clientRowIndex: z,
        };
      })
    );
    setStep(2); // Proceed to the next step
  };

  const updateUserRowIndices = () => {
    console.log("Step3");
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        // Filter visits that include the current user
        const matchingVisits = visits.filter((visit) =>
          visit.userid.includes(user.userid)
        );

        if (matchingVisits.length > 0) {
          // Calculate the maximum row index for this user across all matching visits
          const maxUserRowIndex = Math.max(
            ...matchingVisits.map(
              (visit) => visit[`rowindex${user.userid}`] || 0
            )
          );

          // console.log(
          //   "Matching User ID:",
          //   user.userid,
          //   "Max User Row Index:",
          //   maxUserRowIndex
          // );

          // Set the maximum row index for this user
          return { ...user, rowIndex: maxUserRowIndex };
        }

        return user; // If no matching visits, return user unchanged
      })
    );
    // Proceed to the next step
    setStep(3);
  };

  const updateClientRowIndices = () => {
    console.log("Step 4: Updating Client Row Indices");

    setClients((prevClients) =>
      prevClients.map((client) => {
        // Get all visits with the matching clientid
        const matchingVisits = visits.filter(
          (visit) => visit.clientid === client.clientid
        );

        if (matchingVisits.length > 0) {
          // Calculate the maximum clientRowIndex among the matching visits
          const maxClientRowIndex = Math.max(
            ...matchingVisits.map((visit) => visit.clientRowIndex || 0)
          );

          // console.log(
          //   "Matching Client ID:",
          //   client.clientid,
          //   "Max Client Row Index:",
          //   maxClientRowIndex
          // );

          // Update client with the maximum clientRowIndex
          return { ...client, rowIndex: maxClientRowIndex };
        }

        return client; // Return the client without changes if no match is found
      })
    );
    setStep(4); // Proceed to the next step
  };

  useEffect(() => {
    // Run the first step initially
    if (step === 0) updateRowIndexForVisit();
    if (step === 1) updateClientRowIndexForVisit();
    if (step === 2) updateUserRowIndices();
    if (step === 3) updateClientRowIndices();
  }, [step]);

  // const [visitCheck, setTimeCheck] = useState([]);

  // const [visitChecks, setTimeChecks] = useState();

  // const addToTimeCheck = (sTime, eTime) => {
  //   setTimeCheck((prevTimeCheck) => [
  //     ...prevTimeCheck,
  //     { startvisit: sTime, endvisit: eTime },
  //   ]);
  // };

  const getGridIndex = (visit) => {
    const [hour, minute] = visit.split(":").map(Number);
    return hour * 2 + (minute >= 30 ? 1 : 0);
  };

  const getWidth = (visit) => {
    const [hour, minute] = visit.split(":").map(Number);
    return ((minute % 30) / 30) * 100;
  };

  const handleCloseAssignCarerDialog = () => {
    setOpenAssignCarerDialog(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 50px)",
        backgroundColor: "white",
        border: "1px solid #dddddd",
        overflowY: "auto",
      }}
    >
      {/* Header Row */}
      <Box
        sx={{
          display: "flex",
          height: "35px",
          position: "sticky",
          top: 0,
          backgroundColor: "#ffffff",
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            width: "190px",
            borderBottom: "1px solid #dddddd",
            alignContent: "center",
          }}
        ></Box>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            sx={{
              height: "100%",

              borderBottom: "1px solid #dddddd",
              borderRight: "1px solid #dddddd",
              textAlign: "center",
              position: "relative",
            }}
          >
            {Array.from({ length: 24 }).map((_, index) => (
              <Grid
                item
                xs={0.5}
                key={index}
                sx={{
                  position: "relative",
                  "&::after": {
                    content: `"${index < 10 ? `0${index}` : index}"`, // Displays each number
                    position: "absolute",
                    top: "8px", // Adjusts the vertical position of the numbers
                    left: "0",
                    transform: "translateX(-50%)",
                    fontSize: "12px",
                    variant: "body3",
                  },
                }}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Unallocated Visits Row */}
      {view === "Carer View" && (
        <Box
          sx={{
            display: "flex",
            minHeight: "123px",
            height: `${5 + (URowIndex + 1) * 59}px`, // Use template literal for dynamic height
          }}
        >
          <Box
            sx={{
              width: "190px ! important",
              borderBottom: "1px solid #dddddd",
              alignContent: "center",
              paddingLeft: "8px",
            }}
          >
            <Typography variant="h6">Unallocated</Typography>
          </Box>
          <Box sx={{ width: "100%", position: "relative" }}>
            <Grid
              container
              direction="row"
              sx={{
                height: "100%",
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #dddddd",
                borderRight: "1px solid #dddddd",
              }}
            >
              {visitSlots.map((_, gridIndex) => (
                <Grid
                  item
                  xs={0.25}
                  key={gridIndex}
                  sx={{
                    borderLeft: "1px solid #dddddd",
                    position: "relative",
                  }}
                >
                  {visits
                    .filter((visit) => visit.userid.length === 0 && !visit.run)
                    .map((visit) => {
                      const startIndex = getGridIndex(visit.startTime);
                      const endIndex = getGridIndex(visit.endTime);
                      const gridSpan = endIndex - startIndex;
                      const startWidth = getWidth(visit.startTime);
                      const endWidth = getWidth(visit.endTime);

                      //addToTimeCheck(visit.startTime, visit.endTime);

                      //setTimeChecks(visit.id);

                      if (gridIndex === startIndex) {
                        return (
                          <Box
                            onMouseEnter={() => handleMouseEnter(visit.id)}
                            onMouseLeave={handleMouseLeave}
                            ref={(el) => cardRefs.current.set(visit.id, el)} // Set ref in the map
                            key={visit.id}
                            onClick={() => handlecard(visit.id)}
                          >
                            <Box
                              key={visit.id}
                              sx={{
                                position: "absolute",
                                top: 5 + visit.rowIndex * 59,
                                left: `${startWidth}%`,
                                height: "54px",
                                width: `calc(${
                                  gridSpan * 100 - startWidth + endWidth
                                }% + ${gridSpan - 1}px)`,
                                backgroundColor: "#279989",
                                color: "white",
                                zIndex: 2,
                                borderRadius: "8px",
                                overflow: "hidden",
                                border: "1px solid #279989",
                              }}
                            >
                              <RosterVisitCard visit={visit} view={view} />
                            </Box>
                            {focusedCardId === visit.id && (
                              <Box
                                key={visit.id}
                                sx={{
                                  position: "absolute",
                                  top:
                                    (showAbove ? -248 : 50) +
                                    visit.rowIndex * 59, // Show above or below based on space
                                  left: `${startWidth}%`,
                                  mt: showAbove ? "-9px" : "9px",
                                  width: "150px",
                                  border: "1px solid #279989",
                                  //borderTop: "0px",
                                  borderRadius: "8px",
                                  backgroundColor: "#ffffff",
                                  zIndex: 5,
                                  textAlign: "left",
                                }}
                              >
                                <Box
                                  sx={{
                                    padding: " 5px 10px",
                                    borderBottom: "1px solid #279989",
                                    "&:hover": {
                                      backgroundColor: "#BFE1DC", // Hover color
                                      borderRadius: "8px 8px 0 0",
                                    },
                                  }}
                                  onClick={() => setOpenAssignCarerDialog(true)}
                                >
                                  <Typography variant="body3">
                                    Assign Carer
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "5px 10px",
                                    "&:hover": {
                                      backgroundColor: "#BFE1DC", // Hover color
                                      borderRadius: "0 0 8px 8px",
                                    },
                                  }}
                                >
                                  <Typography variant="body3">
                                    Manage Run
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            {hoveredCardId === visit.id && (
                              <Box
                                key={visit.id}
                                sx={{
                                  position: "absolute",
                                  top:
                                    (showAbove ? -252 : 54) +
                                    visit.rowIndex * 59, // Show above or below based on space
                                  left: `${startWidth}%`,
                                  mt: showAbove ? "-9px" : "9px",
                                  width: "250px",
                                  zIndex: 5,
                                }}
                              >
                                <RosterVisitCardDetails visit={visit} />
                              </Box>
                            )}
                          </Box>
                        );
                      }
                      return null;
                    })}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}

      {/* User Rows with Visits */}
      {view === "Carer View" && (
        <>
          {Users.map((user) => (
            <Box
              key={user.userid}
              sx={{
                display: "flex",
                minHeight: "65px",
                height: `${5 + (user.rowIndex + 1) * 59}px`,
              }}
            >
              <Box
                sx={{
                  width: "190px ! important",
                  borderBottom: "1px solid #dddddd",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <UserCard userDetails={user} />
              </Box>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    height: "100%",
                    backgroundColor: "#E3F2F0",
                    borderBottom: "1px solid #dddddd",
                    borderRight: "1px solid #dddddd",
                  }}
                >
                  {visitSlots.map((_, gridIndex) => (
                    <Grid
                      item
                      xs={0.25}
                      key={gridIndex}
                      sx={{
                        borderLeft: "1px solid #dddddd",
                        position: "relative",
                      }}
                    >
                      {visits
                        .filter(
                          (event) =>
                            event.userid.includes(user.userid) && !event.run
                        )
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);

                          const startIndexActual = getGridIndex(
                            visit.actualStartTime
                          );
                          const endIndexActual = getGridIndex(
                            visit.actualEndTime
                          );
                          const gridSpanActual =
                            endIndexActual - startIndexActual;
                          const startWidthActual = getWidth(
                            visit.actualStartTime
                          );
                          //console.log("startWidthActual", startWidthActual);
                          //console.log("startWidth", startWidth);
                          const endWidthActual = getWidth(visit.actualEndTime);

                          if (
                            gridIndex ===
                            (startIndexActual ? startIndexActual : startIndex)
                          ) {
                            return (
                              <Box
                                onMouseEnter={() =>
                                  handleMouseEnter(visit.id, user.userid)
                                } // Pass user id
                                onMouseLeave={handleMouseLeave}
                                ref={(el) => cardRefs.current.set(visit.id, el)} // Use combined key for unique ref
                                key={visit.id} // Ensure unique key for each combination
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top:
                                      5 +
                                      (visit[`rowindex${user.userid}`] || 0) *
                                        59,
                                    left: `${
                                      startWidthActual
                                        ? startWidthActual
                                        : startWidth
                                    }%`,
                                    height: "54px",
                                    width: `calc(${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) *
                                        100 -
                                      (startWidthActual
                                        ? startWidthActual
                                        : startWidth) +
                                      (endWidthActual
                                        ? endWidthActual
                                        : endWidth)
                                    }% + ${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) - 1
                                    }px)`,
                                    backgroundColor: "#279989",
                                    color: "white",
                                    zIndex: 2,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <RosterVisitCard visit={visit} view={view} />
                                </Box>
                                {hoveredCardId === visit.id &&
                                  hoveredUserId === user.userid && (
                                    <Box
                                      key={visit.id}
                                      sx={{
                                        position: "absolute",
                                        top:
                                          (showAbove ? -252 : 54) +
                                          (visit[`rowindex${user.userid}`] ||
                                            0) *
                                            59, // Show above or below based on space
                                        left: `${startWidth}%`,
                                        mt: showAbove ? "-9px" : "9px",
                                        width: "250px",
                                        zIndex: 5,
                                      }}
                                    >
                                      <RosterVisitCardDetails visit={visit} />
                                    </Box>
                                  )}
                              </Box>
                            );
                          }
                          return null;
                        })}
                      {visits
                        .filter(
                          (event) =>
                            event.userid.includes(user.userid) && !event.run
                        )
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);

                          if (gridIndex === startIndex) {
                            return (
                              <Box
                                key={visit.id}
                                sx={{
                                  position: "absolute",
                                  top:
                                    5 +
                                    (visit[`rowindex${user.userid}`] || 0) * 59,
                                  left: `${startWidth}%`,
                                  height: "54px",
                                  width: `calc(${
                                    gridSpan * 100 - startWidth + endWidth
                                  }% + ${gridSpan - 1}px)`,
                                  zIndex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                  direction: "row",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "17px",
                                    backgroundColor: "#636363",
                                    borderRadius: "10px",
                                    width: "2px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "2px",
                                    backgroundColor: "#636363",
                                    width: "100%",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "17px",
                                    width: "2px",
                                    borderRadius: "10px",
                                    backgroundColor: "#636363",
                                  }}
                                ></Box>
                              </Box>
                            );
                          }
                          return null;
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ))}
        </>
      )}
      {/* Client Rows with Visits */}
      {view === "Client View" && (
        <>
          {Clients.map((Client) => (
            <Box
              key={Client.clientid}
              sx={{
                display: "flex",
                minHeight: "65px",
                height: `${5 + (Client.rowIndex + 1) * 59}px`,
              }}
            >
              <Box
                sx={{
                  width: "190px",
                  borderBottom: "1px solid #dddddd",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                }}
              >
                <ClientCard clientDetails={Client} />
              </Box>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    height: "100%",
                    backgroundColor: "#E3F2F0",
                    borderBottom: "1px solid #dddddd",
                    borderRight: "1px solid #dddddd",
                  }}
                >
                  {visitSlots.map((_, gridIndex) => (
                    <Grid
                      item
                      xs={0.25}
                      key={gridIndex}
                      sx={{
                        borderLeft: "1px solid #dddddd",
                        position: "relative",
                      }}
                    >
                      {visits
                        .filter((event) => event.clientid === Client.clientid)
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);
                          const startIndexActual = getGridIndex(
                            visit.actualStartTime
                          );
                          const endIndexActual = getGridIndex(
                            visit.actualEndTime
                          );
                          const gridSpanActual =
                            endIndexActual - startIndexActual;
                          const startWidthActual = getWidth(
                            visit.actualStartTime
                          );
                          //console.log("startWidthActual", startWidthActual);
                          //console.log("startWidth", startWidth);
                          const endWidthActual = getWidth(visit.actualEndTime);

                          if (
                            gridIndex ===
                            (startIndexActual ? startIndexActual : startIndex)
                          ) {
                            return (
                              <Box
                                onMouseEnter={() => handleMouseEnter(visit.id)}
                                onMouseLeave={handleMouseLeave}
                                ref={(el) => cardRefs.current.set(visit.id, el)} // Set ref in the map
                                key={visit.id}
                              >
                                <Box
                                  key={visit.id}
                                  //ref={cardRef} // Attach ref to measure position
                                  sx={{
                                    position: "absolute",
                                    top: 5 + visit.clientRowIndex * 59,
                                    left: `${
                                      startWidthActual
                                        ? startWidthActual
                                        : startWidth
                                    }%`,
                                    height: "54px",
                                    width: `calc(${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) *
                                        100 -
                                      (startWidthActual
                                        ? startWidthActual
                                        : startWidth) +
                                      (endWidthActual
                                        ? endWidthActual
                                        : endWidth)
                                    }% + ${
                                      (gridSpanActual
                                        ? gridSpanActual
                                        : gridSpan) - 1
                                    }px)`,
                                    backgroundColor: "#ffffff",
                                    color: "white",
                                    zIndex: 2,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    padding: 0,
                                    margin: 0,
                                    border:
                                      visit.userid.length === 0
                                        ? "1px solid #279989"
                                        : "none",
                                  }}
                                >
                                  <RosterVisitCard visit={visit} view={view} />
                                </Box>
                                {hoveredCardId === visit.id && (
                                  <Box
                                    key={visit.id}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        (showAbove ? -252 : 54) +
                                        visit.clientRowIndex * 59, // Show above or below based on space
                                      left: `${
                                        startWidthActual
                                          ? startWidthActual
                                          : startWidth
                                      }%`,
                                      mt: showAbove ? "-9px" : "9px",
                                      width: "250px",
                                      zIndex: 5,
                                    }}
                                  >
                                    <RosterVisitCardDetails visit={visit} />
                                  </Box>
                                )}
                              </Box>
                            );
                          }
                          return null;
                        })}

                      {visits
                        .filter((event) => event.clientid === Client.clientid)
                        .map((visit) => {
                          const startIndex = getGridIndex(visit.startTime);
                          const endIndex = getGridIndex(visit.endTime);
                          const gridSpan = endIndex - startIndex;
                          const startWidth = getWidth(visit.startTime);
                          const endWidth = getWidth(visit.endTime);

                          if (gridIndex === startIndex) {
                            return (
                              <Box
                                key={visit.id}
                                sx={{
                                  position: "absolute",
                                  top: 5 + visit.clientRowIndex * 59,
                                  left: `${startWidth}%`,
                                  height: "54px",
                                  width: `calc(${
                                    gridSpan * 100 - startWidth + endWidth
                                  }% + ${gridSpan - 1}px)`,
                                  zIndex: 1,
                                  alignItems: "center",
                                  display: "flex",
                                  direction: "row",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "17px",
                                    backgroundColor: "#636363",
                                    borderRadius: "10px",
                                    width: "2px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "2px",
                                    backgroundColor: "#636363",
                                    width: "100%",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "17px",
                                    width: "2px",
                                    borderRadius: "10px",
                                    backgroundColor: "#636363",
                                  }}
                                ></Box>
                              </Box>
                            );
                          }
                          return null;
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ))}
        </>
      )}
      {openAssignCarerDialog && (
        <RosterAssignCarerDialog
          openDialog={openAssignCarerDialog}
          handleCloseDialog={handleCloseAssignCarerDialog}
        />
      )}
    </Box>
  );
}
