import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Grid,
  Card,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { getCookie } from "../../../utils/utils";
import { EditTheme } from "../../users/Theme";
import { ToastContainer } from "react-toastify";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import OpenForm from "../../../assets/images/clients/Open_Form.svg";
import Activity from "../../../assets/images/clients/Assessments/Activity.svg";
import Environmental from "../../../assets/images/clients/Assessments/Environmental.svg";
import MentalHealth from "../../../assets/images/clients/Assessments/Mental Health.svg";
import View from "../../../assets/images/clients/view_eye.svg";
import Delete from "../../../assets/images/clients/Delete.svg";
import OralCareAssessment from "../../../assets/images/clients/Assessments/OralCareAssessment.svg";
import SleepingAssessment from "../../../assets/images/clients/Assessments/SleepingAssessment.svg";
import ClinicalAssessment from "../../../assets/images/clients/Assessments/ClinicalAssessment.svg";
import BreathingAssessment from "../../../assets/images/clients/Assessments/BreathingAssessment.svg";
import ContinenceAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import DomesticAssessment from "../../../assets/images/clients/Assessments/DomesticAssessment.svg";
import EquipmentAssessment from "../../../assets/images/clients/Assessments/EquipmentAssessment.svg";
import MovingandHandlingAssessment from "../../../assets/images/clients/Assessments/MovingandHandlingAssessment.svg";
import NutritionandHydrationAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import PainAssessment from "../../../assets/images/clients/Assessments/PainAssessment.svg";
import SkinAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import ActivityRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import COSHHRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import EquipmentRegister from "../../../assets/images/clients/Assessments/EquipmentRegister.svg";
import FinancialRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import MedicationRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import SelfAdministrationofMedicationRiskAssessment from "../../../assets/images/clients/Assessments/Activity.svg";
import CulturalSpiritualSocialandRelationshipsAssessment from "../../../assets/images/clients/Assessments/CulturalSpiritualSocialandRelationshipsAssessment.svg";
import BehavioursThatMayChallengeAssessment from "../../../assets/images/clients/Assessments/BehavioursThatMayChallengeAssessment.svg";
import BehavioursThatMayChallengeRiskAssessment from "../../../assets/images/clients/Assessments/BehavioursThatMayChallengeAssessment.svg";

import Financial from "../../../assets/images/clients/Assessments/Financial.svg";
import Communication from "../../../assets/images/clients/Assessments/Communication.svg";
import Medication from "../../../assets/images/clients/Assessments/Medication.svg";
import PersonalHygiene from "../../../assets/images/clients/Assessments/Personal Hygiene.svg";

export default function CarePlanEdit({
  toggleEditMode,
  clientMainData,
  setAssessmentName,
}) {
  const assessmentImageMap = {
    MentalHealth: MentalHealth,
    Environmental: Environmental,
    Activity: Activity,
    Financial: Financial,
    Communication: Communication,
    Medication: Medication,
    PersonalHygiene: PersonalHygiene,
    BehavioursThatMayChallengeAssessment: BehavioursThatMayChallengeAssessment,
    OralCareAssessment: OralCareAssessment,
    SleepingAssessment: SleepingAssessment,
    ClinicalAssessment: ClinicalAssessment,
    CulturalSpiritualSocialandRelationshipsAssessment:
      CulturalSpiritualSocialandRelationshipsAssessment,
    BreathingAssessment: BreathingAssessment,
    ContinenceAssessment: ContinenceAssessment,
    DomesticAssessment: DomesticAssessment,
    EquipmentAssessment: EquipmentAssessment,
    MovingandHandlingAssessment: MovingandHandlingAssessment,
    NutritionandHydrationAssessment: NutritionandHydrationAssessment,
    PainAssessment: PainAssessment,
    SkinAssessment: SkinAssessment,
    ActivityRiskAssessment: ActivityRiskAssessment,
    COSHHRiskAssessment: COSHHRiskAssessment,
    EquipmentRegister: EquipmentRegister,
    FinancialRiskAssessment: FinancialRiskAssessment,
    BehavioursThatMayChallengeRiskAssessment:
      BehavioursThatMayChallengeRiskAssessment,
    MedicationRiskAssessment: MedicationRiskAssessment,
    SelfAdministrationofMedicationRiskAssessment:
      SelfAdministrationofMedicationRiskAssessment,

    // Add other mappings here as needed
  };
  const [assessmentCard, setAssessmentCard] = useState([]);
  const [clientName, setClientName] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState(""); // State for selected dropdown value

  const [UploadedDocument, setUploadedDocument] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        //setAssessmentCard({});
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment/103`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          // Map the fetched data to include the appropriate assessment_image
          const assessmentsWithImages = data.data.map((assessment) => ({
            ...assessment,
            assessment_image: assessmentImageMap[assessment.assessment_image], // Use the mapped image or a default
          }));

          setAssessmentCard(assessmentsWithImages);

          // setAssessmentCards(data.data);
        } else {
          console.log("Failed to fetch assessments");
        }
      } catch (error) {
        console.error("Error fetching assessments:", error);
      }
    };

    fetchUsersAPI();
  }, []);

  // Function to handle setting an assessment to active
  const handleAddAssessment = () => {
    if (selectedAssessment) {
      setAssessmentCard(
        assessmentCard.map((card) =>
          card.assessment_name === selectedAssessment
            ? { ...card, status: "active" } // Set status to 'active'
            : card
        )
      );
      setSelectedAssessment(""); // Reset the dropdown after adding
    }
  };

  // Function to render assessment cards (only active ones)
  const renderAssessmentCard = (card) => {
    return (
      <Box
        key={card.id} // Use unique key for each card
        textAlign="left"
        onClick={
          () => setAssessmentName(card.assessment_name)
          // navigate(
          //   `/clients/client-dashboard/${clientMainData.personalInfo.client_id}/assessment-form`
          // )
        } // Update the path as needed
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative", // Relative position to position the OpenForm icon inside
          padding: "10px 20px",
          margin: "10px 0",
          width: "100%",
          height: "90px",
          backgroundColor: "#F0FCFA",
          borderRadius: "10px",
          marginLeft: "auto", // Push the box to the right
          transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
          "&:hover": {
            backgroundColor: "#279989", // Hover background color
            "& img": {
              filter: "brightness(0) invert(1)", // Change icon color to white (invert colors)
            },
            "& .cardText": {
              color: "white", // Change text color to white
            },
          },
        }}
      >
        <Box sx={{ height: "55px", width: "55px" }}>
          <img
            style={{
              height: "100%",
              width: "50px",
            }}
            src={card.assessment_image} // Dynamically load the associated image
            alt="Assess Icon"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: "70%",
            marginTop: card.status === "active" ? "18px" : 0,
          }}
        >
          {/* Date */}
          {card.status === "Active" && (
            <Box
              sx={{
                flexGrow: 1,
                maxWidth: "70%",
                position: "absolute", // Absolute position inside the box
                top: "1px", // Distance from the bottom
                right: "1px", // Distance from the right
              }}
            >
              <Typography
                variant="body2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Show ellipsis for overflow
                  maxWidth: "100%", // Adjust based on container width
                }}
              >
                {card.created_at}
              </Typography>
            </Box>
          )}
          {/* Ensure this container doesn't expand too much */}
          {/* Assessment */}
          <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
            <Typography
              variant="h2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              {card.assessment_name}
            </Typography>
            {card.status === "Active" && (
              <Typography
                variant="body2"
                className="cardText"
                sx={{
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Show ellipsis for overflow
                  maxWidth: "100%", // Adjust based on container width
                }}
              >
                {card.first_name} {card.last_name}
              </Typography>
            )}
          </Box>

          {/* Position the OpenForm image at the bottom right */}
          <Box
            sx={{
              position: "absolute", // Absolute position inside the box
              bottom: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
          >
            <img
              style={{
                height: "20%", // Adjust the size as needed
                width: "auto",
              }}
              src={OpenForm}
              alt="Form Icon"
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const renderDocumentCard = (card) => {
    return (
      <Box
        key={card.id} // Use unique key for each card
        textAlign="left"
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative", // Relative position to position the OpenForm icon inside
          padding: "10px 20px",
          margin: "10px 0",
          width: "100%",
          height: "90px",
          backgroundColor: "#F0FCFA",
          borderRadius: "10px",
          marginLeft: "auto", // Push the box to the right
          transition: "background-color 0.3s, color 0.3s", // Smooth transition for background and text color
          "&:hover": {
            backgroundColor: "#279989", // Hover background color
            "& img": {
              filter: "brightness(0) invert(1)", // Change icon color to white (invert colors)
            },
            "& .cardText": {
              color: "white", // Change text color to white
            },
          },
        }}
      >
        <Box sx={{ height: "55px", width: "55px" }}>
          <img
            style={{
              height: "100%",
              width: "auto",
            }}
            src={UploadDocument} // Dynamically load the associated image
            alt="Upload Document"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: "70%",
            marginTop: card.status === "active" ? "18px" : 0,
          }}
        >
          {/* Ensure this container doesn't expand too much */}
          {/* Assessment */}
          <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
            <Typography
              variant="h2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              {card.assessment_name}
            </Typography>

            <Typography
              variant="body2"
              className="cardText"
              sx={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowing text
                textOverflow: "ellipsis", // Show ellipsis for overflow
                maxWidth: "100%", // Adjust based on container width
              }}
            >
              {card.status}
            </Typography>
          </Box>

          {/* Position the OpenForm image at the bottom right */}
          <Box
            sx={{
              position: "absolute", // Absolute position inside the box
              bottom: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
          >
            <img
              style={{
                height: "20%", // Adjust the size as needed
                width: "auto",
              }}
              src={View}
              alt="Form Icon"
            />
          </Box>
          <Box
            sx={{
              position: "absolute", // Absolute position inside the box
              top: "10px", // Distance from the bottom
              right: "10px", // Distance from the right
              height: "auto", // Adjust height and width for positioning
            }}
          >
            <img
              style={{
                height: "20%", // Adjust the size as needed
                width: "auto",
              }}
              src={Delete}
              alt="Form Icon"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        <Box>
          <ClientHeader
            head={`${clientName}'s Care Plan`}
            isHideButton={false}
          />
          <Card sx={{ paddingLeft: 0, marginLeft: 0, marginRight: 0 }}>
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              Complete the initial assessment of the client by determining the
              key fundamental principles.
              <br />
              Evaluate the following assessments for {`${clientName}`}
            </Typography>
          </Card>

          {/* Dropdown and Add Button */}
          {/* <Box sx={{ display: "flex", marginTop: "20px" }}>
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel>Select Assessment</InputLabel>
              <Select
                value={selectedAssessment}
                onChange={(e) => setSelectedAssessment(e.target.value)} // Update selected assessment
                label="Select Assessment"
              >
                {assessmentCard
                  .filter((card) => card.status === "") // Show only assessments with empty status
                  .map((card) => (
                    <MenuItem key={card.id} value={card.assessment_name}>
                      {card.assessment_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleAddAssessment}>
              ADD
            </Button>
          </Box> */}

          {/* Render Active Assessment Cards */}
          <Grid container direction="row" spacing={3}>
            {assessmentCard.map((card) =>
              card.status === "Open" || card.status === "Active" ? (
                <Grid
                  item
                  key={card.id}
                  xxl={3} // 4 cards per row in XXL
                  lg={4} // 3 cards per row in LG
                  md={6} // 2 cards per row in MD
                  xs={12} // 1 card per row in XS (mobile)
                  sx={{}}
                >
                  {renderAssessmentCard(card)}
                </Grid>
              ) : null
            )}
          </Grid>

          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
              margin: "10px 0",
              width: "100%",
              height: "150%",
            }}
          >
            <img
              style={{
                height: "30%",
                width: "auto",
              }}
              src={UploadDocument}
              alt="Uploaded Documents"
            />
            <Typography variant="h2">Uploaded Documents</Typography>
          </Box>
          <Grid container direction="row" spacing={3}>
            {UploadedDocument.length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    alignItems: "center",
                    width: "100%",
                    padding: "20px 0",
                    backgroundColor: "#F0FCFA", // You can change this color to your liking
                    borderRadius: "10px",
                  }}
                >
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                      <img
                        style={{
                          height: "80px",
                          width: "auto",
                        }}
                        src={UploadDocument} // Dynamically load the associated image
                        alt="Upload Document"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#6C7479", // Text color
                        }}
                      >
                        No Documents Yet
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              UploadedDocument.map((card, index) => (
                <Grid
                  item
                  key={card.id}
                  xxl={3} // 4 cards per row in XXL
                  lg={4} // 3 cards per row in LG
                  md={6} // 2 cards per row in MD
                  xs={12} // 1 card per row in XS (mobile)
                  sx={{}}
                >
                  {renderDocumentCard(card)}
                </Grid>
              ))
            )}
          </Grid>
          <ToastContainer />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
