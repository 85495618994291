import React, { useState } from 'react'
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Button,
    Tab,
  } from "@mui/material";
import useStyles from '../styles/useStyles';
function MangeMedicationTable({medicationData}) {
    const [selectedMedications, setSelectedMedications] = useState([]);
    const classes = useStyles();
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          setSelectedMedications(medicationData.map((med) => med.medication_id));
        } else {
          setSelectedMedications([]);
        }
      };
    
      const handleCheckboxClick = (medicationId) => {
        setSelectedMedications((prevSelected) =>
          prevSelected.includes(medicationId)
            ? prevSelected.filter((id) => id !== medicationId)
            : [...prevSelected, medicationId]
        );
      };
    
      const onEdit = (medicationId) => {
        const selectedMedication = medicationData.find(
          (med) => med.medication_id === medicationId
        );
        if (selectedMedication) {
          // Pass `selectedMedication` data to the edit form
          console.log("Editing medication:", selectedMedication);
        }
      };
  return (
    <Grid>
       <Box sx={{width:'100%',   boxShadow: "0px 0px 10px 0px #2799894D",borderRadius:'10px'}}>
       <TableContainer sx={{borderRadius:'10px'}}>
          <Table className={classes.medicationTable}>
            <TableHead sx={{backgroundColor:'#279989',borderRadius:'10px'}}>
              <TableRow>
                <TableCell padding="checkbox" className={classes.medicationTableHeaderCell}>
                  <Checkbox
                   sx={{color:'white'}}
                    indeterminate={
                      selectedMedications.length > 0 &&
                      selectedMedications.length < medicationData.length
                    }
                    checked={
                      medicationData.length > 0 &&
                      selectedMedications.length === medicationData.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Medication</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Dose</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Type</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Route</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Support</TableCell>
                <TableCell className={classes.medicationTableHeaderCell} >Frequency</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Start Date</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>End Date</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Created By</TableCell>
                <TableCell className={classes.medicationTableHeaderCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicationData.map((med) => (
                <TableRow key={med.medication_id} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedMedications.includes(med.medication_id)}
                      onChange={() => handleCheckboxClick(med.medication_id)}
                    />
                  </TableCell>
                  <TableCell>{med.nhs_medicine_name}</TableCell>
                  <TableCell>{med.quantity_each_dose}</TableCell>
                  <TableCell>{med.medication_type}</TableCell>
                  <TableCell>{med.medication_route_name}</TableCell>
                  <TableCell>{med.medication_support}</TableCell>
                  <TableCell>
                    {med.medication_type === "Blister Pack"
                      ? med.blister_pack_frequency
                      : med.medication_type === "When required PRN"
                      ? med.prn_time_frame
                      : med.scheduled_frequency}
                  </TableCell>
                  <TableCell>
                    {med.medication_type === "Blister Pack"
                      ? med.blister_pack_start_date
                      : med.medication_type === "When required PRN"
                      ? med.prn_start_date
                      : med.scheduled_start_date || med.by_exact_start_date}
                  </TableCell>
                  <TableCell>
                    {med.medication_type === "Blister Pack"
                      ? med.blister_pack_end_date
                      : med.medication_type === "When required PRN"
                      ? med.prn_end_date
                      :  med.scheduled_end_date ||med.by_exact_end_date}
                  </TableCell>
                  <TableCell>{med.created_by}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => onEdit(med.medication_id)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
       </Box>
    </Grid>
  )
}

export default MangeMedicationTable