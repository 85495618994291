import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import Checkbox from "@mui/material/Checkbox";
import User from "../../assets/icons/User.svg";
import { getCookie } from "../../utils/utils";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Car from "../../assets/images/userTableIcons/UserTableCar.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#279989",
    padding: "8px 16px",
    border: "none",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "2px solid #eefefa",
    fontSize: 14,
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eefefa",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RosterAssignCarerDialog({
  openDialog,

  handleCloseDialog = () => {},
}) {
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const selectedUserLimit = 2;

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/user`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok === true) {
          setUsersList(data.data);
        } else {
          console.log("get all failed");
          setUsersList([]);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchUsersAPI();
  }, []);

  const handleCheckboxChange = (event, userId) => {
    setSelectedUser((prev) => {
      // Toggle the userId in the selectedUsers array based on whether the checkbox is checked or not
      const selectedUser = event.target.checked
        ? [...prev, userId] // Add userId if checked
        : prev.filter((id) => id !== userId); // Remove userId if unchecked
      return selectedUser;
    });
  };

  const onConfirmFun = () => {
    //handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "80%", // Set your desired width here
          maxWidth: "1200px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          {/* Header here */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Left Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "25px", marginRight: "10px" }}
                src={User}
                alt="User"
              />
              <Typography
                variant="h3"
                sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
              >
                Assign User
              </Typography>
            </Box>
            {/* Right Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField sx={{ minWidth: "250px" }}></TextField>
              <Typography
                variant="h3"
                sx={{
                  margin: 0,
                  padding: 0,
                  textWrap: "nowrap",
                  width: "250px",
                }}
              >
                {selectedUser.length}/{selectedUserLimit} Users Selected
              </Typography>
            </Box>
          </Box>
          {/* Table Content */}
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "370px", overflow: "auto" }}
            >
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Name
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Contact
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Gender
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Admin
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                          width: "80%",
                        }}
                      >
                        Status
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflow: "auto" }}>
                  {usersList
                    .filter((user) => user.status === 1)
                    .map((user, index) => (
                      <StyledTableRow key={user.id}>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onChange={(event) =>
                              handleCheckboxChange(event, user.id)
                            }
                            disabled={
                              !selectedUser.includes(user.id) &&
                              selectedUser.length >= selectedUserLimit
                            } // Disable if limit reached and this checkbox is not selected
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            maxWidth: "150px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.first_name} {user.last_name}
                            {user.drive == 0 ? (
                              <img
                                src={Car}
                                alt="Car"
                                style={{
                                  marginLeft: "10px",
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : null}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.country_code} {user.contact_number}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.gender == "M" ? "Male" : "Female"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.admin == "1" ? "Yes" : "No"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              border:
                                user.status == "1"
                                  ? "1px solid #03AE71"
                                  : "1px solid #F5891D", // Change border color based on status
                              borderRadius: "8px",
                              backgroundColor:
                                user.status == "1" ? "#F1FFF3" : "#FFF0E3",
                              padding: "4px",
                              color: user.status == "1" ? "#03AE71" : "#F5891D",
                              width: "80%",
                            }}
                          >
                            {user.status == "1" ? "Active" : "Inactive"}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
