import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
  Grid,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Calendar, Sessions, Days } from "../../reusableComponents";
import ToDo_List_1 from "../../../assets/images/clients/todoList_Icon/ToDo_List_1.gif";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import { useParams } from "react-router-dom";

const EditTodoForm = ({
  open,
  handleClose,
  clientId,
  content,
  selectedTodo,
  onSuccess = () => {}, // changes here
}) => {
  const navigate = useNavigate();
  const { userID } = useParams();
  const [form, setForm] = useState(selectedTodo);
  const [sessionsList, setSessionsList] = useState([]);

  const start_dateRef = useRef();
  const end_dateRef = useRef();
  const select_daysRef = useRef();
  const choose_sessionsRef = useRef();

  useEffect(() => {
    const choosedSessionsList = form?.choose_sessions.map(
      (eachSession, index) => ({
        id: parseInt(eachSession) || 0,
        text: form?.session_type[index] || "",
        value: form?.session_type[index] || "",
      })
    );

    let convertedData = {
      ...form,
      essential: form.essential === 1 ? true : false,
      session_type: choosedSessionsList,
    };

    setForm(convertedData);
    setSessionsList(choosedSessionsList);
  }, [selectedTodo]);

  const handleChange = (name, value) => {
    setForm((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: value };
      return updatedForm;
    });
  };
  const currentDate = dayjs().startOf("day");

  const handleDateChange = (date, name) => {
    setForm((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      return updatedForm;
    });
  };

  const TitleComponent = ({ imgUrl, text }) => {
    return (
      <div>
        <img alt="prefdefined" src={imgUrl} style={{ width: "60px" }} />
        <Typography
          sx={{
            color: "#1E3037",
            fontFamily: "Arvo",
            fontSize: "18px",
            fontWeight: 400,
            margin: "10px 0px",
          }}
        >
          {text}
        </Typography>
      </div>
    );
  };

  const updateTodoAPI = async () => {
    try {
      const token = getCookie();

      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/todo/${userID}/${selectedTodo.id}`;

      // added changes
      const body = {
        start_date: form?.start_date,
        end_date: form?.end_date,
        select_days: form?.select_days || [],
        choose_sessions: form?.session_type.map((each) => each.id) || [],
        essential: form?.essential === true ? 1 : 0,
        additional_notes: form?.additional_notes || "",
      };

      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        onSuccess();
        handleClose();
      } else {
        console.log("get all failed");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const checkIsValidsGiven = () => {
    if (start_dateRef.current) {
      start_dateRef.current.handleValidation();
    }

    if (end_dateRef.current) {
      end_dateRef.current.handleValidation();
    }
    if (select_daysRef.current) {
      select_daysRef.current.handleValidation();
    }
    if (choose_sessionsRef.current) {
      choose_sessionsRef.current.handleValidation();
    }

    return (
      start_dateRef.current.handleValidation() &&
      end_dateRef.current.handleValidation() &&
      select_daysRef.current.handleValidation() &&
      choose_sessionsRef.current.handleValidation()
    );
  };

  const onSubmitForm = () => {
    if (checkIsValidsGiven()) {
      // Calling API
      updateTodoAPI();
    }
  };

  return (
    open && (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9,
          background: "rgba(192, 198, 201, 0.3)",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            maxWidth: "1100px",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: "10px 40px ",
              background: "#fff",
              borderRadius: "20px",
              margin: "auto",
              width: "95%",
              height: "90vh",
              overflow: "auto",
              //   height:"100%",
            }}
          >
            <TitleComponent imgUrl={ToDo_List_1} text="Added To-Do’s" />
            <Grid
              container
              spacing={2}
              sx={{
                //  padding: "20px",
                textAlign: "left",
              }}
            >
              {/* Search Input */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "#1E3037",
                    fontFamily: "Arvo",
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: "0px",
                    textAlign: "left",
                  }}
                >
                  Selected To-Do
                </Typography>
                <TextField
                  name="additional_notes"
                  variant="outlined"
                  className="multiline-textfield"
                  fullWidth
                  placeholder="Selected To-Do"
                  value={form.todo_description}
                ></TextField>
              </Grid>
              {/* Days of the week selection */}
              <Grid item xs={12} md={5.75}>
                <Days
                  ref={select_daysRef} // Pass the ref to the Days component
                  initialSelectedDays={form.select_days}
                  onDaysChange={(updatedDays) => {
                    handleChange("select_days", updatedDays || []);
                  }}
                  required={true}
                />
              </Grid>

              <Grid item md={0.5} xs={false} />
              {/* Sessions selection */}
              <Grid item xs={12} md={5.75}>
                <Grid item xs={12}>
                  <Sessions
                    ref={choose_sessionsRef} // Pass the ref to the Sessions component
                    preFilledvalue={form.session_type}
                    onSessionSelect={(sessions) => {
                      handleChange("session_type", sessions || []);
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={start_dateRef}
                  label="Start Date"
                  onDateChange={handleDateChange}
                  required
                  preFilledvalue={form.start_date}
                  name="start_date"
                  minDate={currentDate}
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={end_dateRef}
                  label="End Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={form.end_date}
                  name="end_date"
                  minDate={form.start_date ? form.start_date : currentDate } 
                  // required
                />
              </Grid>

              {/* Additional Notes */}
              <Grid item xs={12}>
                <FormLabel>
                  <Typography
                    variant="h6"
                    sx={{
                      padding: 0,
                      fontFamily: "lora",
                    }}
                  >
                    Additional Notes
                  </Typography>
                </FormLabel>
                <TextField
                  name="additional_notes"
                  variant="outlined"
                  className="multiline-textfield"
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Additional Notes"
                  todo_description
                  value={form.additional_notes}
                  onChange={(e) =>
                    handleChange("additional_notes", e.target.value)
                  }
                ></TextField>
              </Grid>

              {/* Alert Checkbox */}
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.essential}
                      onChange={() =>
                        handleChange("essential", !form.essential)
                      }
                    />
                  }
                  label="Check this to get an alert if the Todo is not completed."
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 4 }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    // background: "red",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="customcancel"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="customsave"
                    color="primary"
                    onClick={onSubmitForm}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  );
};

export default EditTodoForm;
