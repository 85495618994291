import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Name, Calendar, RadioInput } from "../../reusableComponents"; // Adjust the path
import { AssessmentCardView } from "./AssessmentCardView";

export const AssessmentCardEdit = ({
  assessmentQuestions,
  assessmentName,
  user,
  toggleEditMode,
}) => {
  const currentUserId = `${user.id}`;

  const [signUserId, setSignUserId] = useState(`${user.id}`); // change this to the previous user's ID from backend

  const signName = `${user.first_name + " " + user.last_name}`;

  const [form, setForm] = useState([]);

  const [sign1, setSign1] = useState("");
  const [date1, setDate1] = useState("");

  const [isFormDisable, setIsFormDisable] = useState(false); //To check if the the fields should be disabled

  const [sign1Submitted, setSign1Submitted] = useState(false); //To check if 2nd sign should be added

  const handleRadioChange = (value, index) => {
    setForm((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const [isCheckboxTrue, setIsCheckboxTrue] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxTrue(!isCheckboxTrue);
  };

  const handleValueChange = (value) => {
    console.log("Entered Text:", value);
  };

  const handleSign1 = (value) => {
    setSign1(value);
  };
  const handleDate1 = (value) => {
    setDate1(value);
  };

  const handleDateChange = (date) => {
    console.log("Selected Date:", date);
  };

  const handleSignSubmit = () => {
    date1 ? setIsFormDisable(true) : setIsFormDisable(false);
    date1 ? setSign1Submitted(true) : setSign1Submitted(false);
    setSignUserId(currentUserId);
  };

  const [isChecked, setIsChecked] = useState(false);

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    console.log("Hereby Confirmation ", isCheckboxTrue);
  }, [isCheckboxTrue]);

  return (
    <Box
      sx={{
        textAlign: "Left",
        height: "100%",
        overflow: "auto",
        paddingRight: 2,
        width: "100%",
        boxShadow: "0px 0px 19px 7px #",
      }}
    >
      {assessmentQuestions.length > 0 ? (
        assessmentQuestions
          .filter((assessment) => assessment.assessment_name === assessmentName)
          .map((assessment, index) => (
            <Box key={index}>
              <Typography variant="h4">{assessment.assessment_name}</Typography>
              {assessment.questionslist.map((question, index, arr) => (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: "4px",
                      marginBottom: "-20px",
                    }}
                  >
                    {question.questionname}
                  </Typography>

                  {index == arr.length - 1 ? null : (
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name={`Radio${index}`}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onValueChange={(e) => {
                          handleRadioChange(e, `Radio${index}`);
                        }}
                      />

                      {form[`Radio${index}`] === "yes" ? (
                        <Name
                          name={index + " TextBox"}
                          className="multiline-textfield"
                          label="Please Mention"
                          placeholder="Please Mention"
                          multiline
                          rows="7"
                          onValueChange={handleValueChange}
                        ></Name>
                      ) : null}
                    </Container>
                  )}
                </>
              ))}

              <Name
                className="multiline-textfield"
                placeholder="Care properties and safety concerns"
                multiline
                rows="7"
                onValueChange={handleValueChange}
              />

              <FormLabel sx={{ fontSize: 18 }}>
                Signatures of All Involved Admins in the Assessment
              </FormLabel>

              <Grid //First signature
                container
                direction="row"
                alignItems="top"
                sx={{
                  gap: 8,
                  p: 2,
                  borderRadius: "8px",
                  backgroundColor: "#F0FCFA",
                  mb: -2,
                }}
              >
                <Grid item>
                  <Name
                    label="Name"
                    value={signName}
                    placeholder={signName}
                    onValueChange={handleSign1}
                    disabled={true}
                  />
                </Grid>
                <Grid item>
                  <Calendar
                    label="Date"
                    onDateChange={handleDate1}
                    disabled={isFormDisable}
                  />
                </Grid>
              </Grid>

              {!sign1Submitted ||
              (currentUserId == signUserId && sign1Submitted) ||
              currentUserId == signUserId ? null : ( //checks if the current user already filled or not
                <Grid //Second signature
                  container
                  direction="row"
                  alignItems="top"
                  sx={{
                    gap: 8,
                    p: 2,
                    borderRadius: "8px",
                    backgroundColor: "#F0FCFA",
                  }}
                >
                  <Grid item xs={10} lg={4}>
                    <Name
                      label="Name"
                      placeholder="Name"
                      onValueChange={handleValueChange}
                    />
                  </Grid>
                  <Grid item xs={10} lg={4}>
                    <Calendar label="Date" onDateChange={handleDateChange} />
                  </Grid>
                </Grid>
              )}

              <Grid item>
                <FormControlLabel
                  label={
                    "I hereby confirm the accuracy of the information provided above"
                  }
                  control={<Checkbox onChange={handleCheckboxChange} />}
                />
              </Grid>

              <Grid item sx={{ mt: 3, mb: 3 }}>
                <Box sx={{ width: "fit-content" }}>
                  <Button
                    variant="customcancel"
                    onClick={() => {
                      toggleEditMode();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="customsave"
                    onClick={() => {
                      handleSignSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Box>
          ))
      ) : (
        <p>No assessment questions available</p>
      )}
    </Box>
  );
};
