import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import { getCookie } from "../../../utils/utils";
import MangeMedicationTable from "./MangeMedicationTable";

function ManageMedication({ clientMainData,clientId }) {
  const [userName, setUserName] = useState("");
  const [medicationData, setMedicationData] = useState([]);
  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);
  useEffect(() => {
    const apiUrl = `https://3.8.158.142:8443/api/medication?client_id=${102}`;
    const fetchMedicationData = async ()=>{
      try{
        const response = await fetch(apiUrl,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie()}`
          }
        });
        if(!response.ok){
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data.data,'=======================>');
        setMedicationData(data.data);
      } catch (error){
        console.log(error);
      }
    }
    fetchMedicationData();
  }, [clientId]);
  return (
    <Grid container>
      <Box sx={{ width: "100%" }}>
        <ClientHeader head={`Manage Medication for ${userName}`} />
      </Box>
      <MangeMedicationTable medicationData ={medicationData} /> 
    </Grid>
  );
}

export default ManageMedication;
