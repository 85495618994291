import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, IconButton } from "@mui/material";
import { RosterDropdown, RosterCalendar } from "../reusableComponents";
import { RosterViews, RosterRuns, RosterTeams } from "../../cms/dropDown";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/utils";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosRounded";
import dayjs from "dayjs"; // For handling date manipulation

export default function RosterDashboardHeader({ setView }) {
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [selectedView, setSelectedView] = useState("Carer View"); // Initialize with today's date

  useEffect(() => {
    setView(selectedView);
  }, [selectedView]);
  const handlePreviousDay = () => {
    // Subtract 1 day from the selected date
    const newDate = dayjs(selectedDate).subtract(1, "day");
    setSelectedDate(newDate);
  };

  // Handle Next Day Button
  const handleNextDay = () => {
    // Add 1 day to the selected date using dayjs
    const newDate = dayjs(selectedDate).add(1, "day");

    // Update the selected date state
    setSelectedDate(newDate);
  };
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const newsDate = dayjs(newDate);
  };
  const handleselect = (value) => {
    setSelectedView(value ? value?.text : "");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        backgroundColor: "#279989",
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: " 8px 8px 0 0",
      }}
    >
      <Box
        sx={{ width: "80%", display: "flex", paddingLeft: "20px", gap: "30px" }}
      >
        <Box sx={{ width: "130px" }}>
          <RosterDropdown
            placeholder="Carer View"
            onDropdownSelect={handleselect}
            list={RosterViews}
            preFilledvalue={selectedView}
            nolabel
          />
        </Box>
        <Box sx={{ width: "130px" }}>
          <RosterDropdown
            placeholder="Runs"
            //onDropdownSelect={handleselect}
            list={RosterRuns}
            //preFilledvalue={}
            nolabel
          />
        </Box>
        <Box sx={{ width: "110px" }}>
          <RosterDropdown
            placeholder="Teams"
            //onDropdownSelect={handleselect}
            list={RosterTeams}
            //preFilledvalue={}
            nolabel
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center the DayCalendar box
          gap: 2, // Space between items
          flexGrow: 1, // Ensures it takes up available space
          position: "absolute",
          left: "50%", // Horizontal center
          top: "50%",
          transform: "translate(-50%, -50%)", // Adjusts for centering
        }}
      >
        {/* Previous Day Button */}
        <IconButton
          onClick={handlePreviousDay}
          sx={{ marginRight: "0px ! important" }}
        >
          <ArrowBackIcon sx={{ width: "85%", height: "85%", color: "white" }} />
        </IconButton>

        {/* DayCalendar (DatePicker from Material UI) */}
        <RosterCalendar
          //label="Select Date"
          preFilledvalue={selectedDate}
          onDateChange={handleDateChange}
          nolabel
        />

        {/* Next Day Button */}
        <IconButton onClick={handleNextDay}>
          <ArrowForwardIcon
            sx={{ width: "85%", height: "85%", color: "white" }}
          />
        </IconButton>
      </Box>
      <Link
        to="/roster/create-run"
        style={{
          textDecoration: "none",
          position: "absolute",
          right: "20px",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            fontFamily: "Arvo",
            fontSize: "14px",
            backgroundColor: "white",
            height: "30px",
            color: "black",
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #279989",
            padding: "5px 14px",
            "&:hover": {
              backgroundColor: "white", // Hover color
              //color: "#279989",
              padding: "5px 20px",
              //boxShadow: "0px 4px 10px white",
            },
            "&:disabled": {
              backgroundColor: "#E8E8E8", // Hover color
              color: "#279989",
              border: "1px solid #279989",
            },
          }}
        >
          Create Run
        </Button>
      </Link>
    </Box>
  );
}

//export default RosterDashboard;
