import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import AddMedicationForm from "./AddMedicationForm";
import useStyles from "../styles/useStyles";
import { getCookie } from "../../../utils/utils";

function AddMedication({clientId,setActiveTabId}) {
  const [medicines , setmedicines] = useState([]);
  const [medicationroute, setmedicationroute] = useState([]);
  const classes = useStyles()
  useEffect(()=>{
    const fetchUsersAPI = async () => {
      try {
        const token = getCookie();
        const URL = 'https://3.8.158.142:8443/api/medication-nhs-medicine-list';
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        setmedicines(data.data);
        console.log()
        if (response.ok === true) {
         setmedicines(data.data);
        } else {
          console.log("get all failed");  
          setmedicines([]);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchUsersAPI();
  },[])
  useEffect(()=>{
    const fetchUsersAPI = async () => {
      try {
        const token = getCookie();
        const URL = 'https://3.8.158.142:8443/api/medication-route';
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        setmedicationroute(data.data);
        console.log()
        if (response.ok === true) {
          setmedicationroute(data.data);
        } else {
          console.log("get all failed");  
          setmedicationroute([]);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchUsersAPI();
  },[])

  return (
    <Grid container>
      <Box  className={classes.header}>
        <ClientHeader head={"Add New Medication"} />
      </Box>
      <Box className={classes.content}>
        <AddMedicationForm clientId = {clientId } medicines = {medicines} medicationroute = {medicationroute} setActiveTabId={setActiveTabId} />
      </Box>
    </Grid>
  );
}

export default AddMedication;



