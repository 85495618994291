import React, { useState, forwardRef } from "react";
import { Box, TextField, Autocomplete, Checkbox } from "@mui/material";

const MultipleSelectDropdown = forwardRef(({ list, required = false }, ref) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  return (
    <Box sx={{ textAlign: "left" }}>
      <Autocomplete
        multiple
        options={list}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.text || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedOptions}
        onChange={(event, newValue) => setSelectedOptions(newValue)} // Update selected options
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={selected}
              sx={{
                padding: "0 8px 0 0",
                color: selected ? "#279989" : "#969999",
              }}
            />
            {option.text}
          </li>
        )}
        renderTags={(value) => {
          const maxVisible = 1;
          const visibleLabels = value
            .slice(0, maxVisible)
            .map((option) => option.text)
            .join(", ");
          const additionalCount = value.length - maxVisible;

          return (
            <span>
              {visibleLabels}
              {additionalCount > 0 && `, +${additionalCount}`}
            </span>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectedOptions.length === 0 ? "Select" : ""}
            fullWidth
            InputProps={{ ...params.InputProps, readOnly: true }} // Disable typing
            sx={{
              "& .MuiInputBase-input": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer", // Show pointer cursor to indicate clickability
              },
            }}
          />
        )}
      />
    </Box>
  );
});

export default MultipleSelectDropdown;
