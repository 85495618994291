import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Card, Button, Box, Typography } from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Admin from "../../../assets/images/users/Admin.svg";
import { ViewTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import EYE from "../../../assets/images/users/view.svg";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import { getCookie } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import { AssessmentCardView } from "./AssessmentCardView";
import { AssessmentCardEdit } from "./AssessmentCardEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CarePlanInformationDialog from "./carePlanInformationDialog";

const AssessmentFormView = ({ clientId, name, assessmentName }) => {
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  const [isEditToggle, setIsEditToggle] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [isNewAssessmentToggle, setIsNewAssessmentToggle] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const toggleEditMode = () => {
    !isEditToggle ? setIsEditToggle(true) : setIsEditToggle(false);
  };

  const toggleNewAssessmentMode = () => {
    !isNewAssessmentToggle
      ? setIsNewAssessmentToggle(true)
      : setIsNewAssessmentToggle(false);
  };

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      //if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment-questions-list`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          // Map the response to transform it as required
          const modifiedAssessmentList = data.finalData.map((assessment) => ({
            assessment_name: assessment.assessment_name,
            questionslist: assessment.questions.map((question) => ({
              questionid: question.id,
              questionname: question.question_name.replace("<name>", name),
            })),
          }));

          // Update the state with the modified data
          setAssessmentQuestions(modifiedAssessmentList);
          console.log(
            "modifiedAssessmentList",
            modifiedAssessmentList.assessment_name
          );
        } else {
          setAssessmentQuestions([]);
        }
      } catch (e) {
        console.log("Error fetching assessment questions:", e.message);
        setAssessmentQuestions([]);
      }
    };
    fetchAssessmentQuestions();
  }, []);
  useEffect(() => {
    console.log("assessmentQuestions", assessmentQuestions);

    assessmentQuestions.forEach((assessment, index) => {
      console.log(`Assessment ${index + 1} Name:`, assessment.assessment_name);
      assessment.questionslist.forEach((question) => {
        console.log("Question Name:", question.questionname);
      });
    });
  }, [assessmentQuestions]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        {/* <Card> */}
        <ClientHeader head={`${name}'s Care Plan`} isHideButton={true} />
        {!isEditToggle ? (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={toggleEditMode}
            sx={{
              margin: 0,
              height: "35px",
              position: "absolute",
              top: "16px",
              zIndex: 11,
              right: "220px",
            }}
          >
            Edit
          </Button>
        ) : null}
        <Button
          variant="outlined"
          color="secondary"
          onClick={toggleNewAssessmentMode}
          sx={{
            margin: 0,
            height: "35px",
            position: "absolute",
            top: "16px",
            right: "25px",
            zIndex: 11,
          }}
        >
          New Assessment
        </Button>

        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  margin: 0,
                  marginRight: "20px",
                  backgroundColor: isEditToggle ? "#F0FCFA" : "white",
                }}
              >
                {isEditToggle ? (
                  <AssessmentCardEdit
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={assessmentName}
                    user={user}
                    toggleEditMode={toggleEditMode}
                  ></AssessmentCardEdit>
                ) : (
                  <AssessmentCardView
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={assessmentName}
                    user={user}
                  ></AssessmentCardView>
                )}
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                  <br />
                  {isNewAssessmentToggle ? (
                    <div
                      style={{ paddingBottom: "4px" }}
                      // key={index}
                      // onClick={() => selectedAssesment(a.id)}
                      onClick={() => setOpenDialog(true)}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          border: "2px solid #F0FCFA",
                          minheight: "60px",
                          padding: "5px 15px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          background: "#C0EBE4",
                          cursor: "pointer",
                          border: "2px solid #F0FCFA",
                          // a.id === activeAssessment
                          //   ? "2px solid #279989"
                          //   : "2px solid #F0FCFA",
                          "&:hover": {
                            border: "2px solid #279989", // Correct way to use :hover in MUI sx
                          },
                        }}
                      >
                        <Grid item xs={2} sx={{ margin: "0 2px" }}>
                          <Box>
                            <img
                              src={ASSESMENT_FILE}
                              alt="user logo"
                              height="35px"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ margin: "0 1px" }}>
                          <Box sx={{ paddingLeft: "10px" }}>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                                paddingBottom: 0,
                              }}
                              variant="h5"
                            >
                              {/* {sign} */}
                              Sign 2 {/* set this sign name from the backend */}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Lora",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                color: "#48464D",
                              }}
                              variant="h6"
                            >
                              {/* {formatDates(a.date_2)} */}
                              Date 2 {/* set this date from the backend */}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} sx={{ margin: "0 1px" }}>
                          {/* </Box> */}

                          <Box>
                            <img
                              src={EYE}
                              // alt={index}
                              style={{ width: "22px", height: "22px" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {openDialog ? (
          <CarePlanInformationDialog
            assessmentQuestions={assessmentQuestions}
            assessmentName={assessmentName}
            openDialog={openDialog}
            handleCloseDialog={handleClose}
            user={user}
          />
        ) : null}
      </Container>
    </ThemeProvider>
  );
};

export default AssessmentFormView;
