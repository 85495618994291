import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import UserDriveCar from "../../assets/images/roster/UserDriveCar.svg";

export default function RosterUserCard({ userDetails }) {
  const words = userDetails.name.split(" ");
  const initials =
    words.length > 1
      ? `${words[0][0]}${words[words.length - 1][0]}` // First and last word initials
      : words[0][0]; // If only one word, use the first initial

  const [hovered, setHovered] = useState(false);

  return (
    <Box
      sx={{
        position: "relative", // Needed for absolute positioning of the hover box
        width: "100%",
        height: "60px",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Main Content Box */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          boxSizing: "border-box", // Ensure padding is included in width calculations
        }}
      >
        {/* LeftBox */}
        <Box sx={{ width: "30%", margin: "0px" }}>
          <Avatar
            sx={{
              bgcolor: "#279989",
              color: "white",
              fontFamily: "Arvo",
              fontSize: "17px",
            }}
          >
            {initials}
          </Avatar>
        </Box>
        {/* RightBox */}
        <Box
          sx={{
            width: "70%",
            margin: "0px",
            alignItems: "left",
            textAlign: "left",
            padding: "0px 2px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lora",
              fontSize: "14px",
              height: "25px",
              overflow: "hidden", // Hides overflow text
              textOverflow: "ellipsis",
              padding: "0px",
            }}
          >
            {userDetails.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "8px",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#279989",
                color: "white",
                fontSize: "10px",
                width: "20px",
                height: "20px",
                fontFamily: "Arvo",
              }}
            >
              {userDetails.hours}
            </Avatar>

            {userDetails.drive === 1 ? (
              <img src={UserDriveCar} alt="UserDriveCar" />
            ) : null}
          </Box>
        </Box>
      </Box>
      {/* Hovered Box */}
      {hovered && (
        <Box
          sx={{
            position: "absolute",
            top: -35,
            left: "100%",
            width: "210px",
            height: "130px",
            marginLeft: "8px",
            backgroundColor: "#279989",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 10,
            "&::before": {
              content: '""',
              position: "absolute",
              top: "50%", // Center vertically
              left: "-14px", // Position it on the left side of the box
              transform: "translateY(-50%)", // Center the arrow vertically
              borderStyle: "solid",
              borderWidth: "8px", // Controls the size of the arrow
              borderColor: "transparent #279989 transparent transparent ", // Left arrow
            },
          }}
        >
          {/* Hovered Content Box */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "left",
              width: "100%",
              height: "100%",
            }}
          >
            {/* User Name*/}
            <Typography
              sx={{
                width: "100%",
                color: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: "0px 5px",
              }}
              variant="body0"
            >
              Role: HCA
            </Typography>
            {/* User Role */}
            <Typography
              sx={{
                width: "100%",
                color: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: "0px 5px",
              }}
              variant="body0"
            >
              Contact No: 07425677888
            </Typography>
            {/* User Contact Details */}

            <Typography
              variant="body0"
              sx={{
                width: "100%",
                color: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: "0px 5px",
              }}
            >
              DOB: 28-11-1999
            </Typography>
            <Typography
              variant="body0"
              sx={{
                width: "100%",
                color: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: "0px 5px",
              }}
            >
              DBS No: 142354656744
            </Typography>
            <Typography
              variant="body0"
              sx={{
                width: "100%",
                color: "white",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                padding: "0px 5px",
              }}
            >
              DBS Expiry: 28-11-1999
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
