import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const RadioInput = forwardRef(
  (
    {
      label,
      options = [],
      preFilledvalue,
      onValueChange,
      required = false,
      disabled = false,
    },
    ref
  ) => {
    const [value, setValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
      setValue(preFilledvalue);
      setErrorMessage("");
    }, [preFilledvalue]);
    // Function to handle changes in the radio group
    const handleChange = (event) => {
      const selectedValue = event.target.value;
      setValue(selectedValue);
      setErrorMessage(""); // Clear error message as soon as value changes
      onValueChange(selectedValue); // Notify parent component about value change
    };

    // Function to validate the input
    const handleValidation = () => {
      if (required && !value) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            {label}
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <RadioGroup
          row // Add this prop to display the radio buttons in a single row
          value={value}
          onChange={handleChange}
          sx={{ marginTop: "8px" }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio disabled={disabled} />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        {errorMessage && (
          <Typography variant="errmsg" sx={{ marginTop: "2px" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }
);

export default RadioInput;
