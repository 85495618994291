import React from "react";
import { Box, Typography, Button } from "@mui/material";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";
import LogContent from "./LogContent";

export default function LogDashboard() {
  const count = 10;
  return (
    <Box sx={{ width: "100%" }}>
      <TopContentContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitilize",
              fontSize: "16px",
              fontWeight: "600",
              color: "#279989",
              display: "flex",
              alignItems: "center",
            }}
          >
            Log
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Button variant="outlinedSecondary">Download</Button>
            <Button variant="outlinedSecondary">Action Required</Button>
            <Button variant="outlinedSecondary">Follow Up</Button>
          </Box>
        </Box>
      </TopContentContainer>
      <ContentContainer isTransparentBg={false}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "100%",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          <LogContent />
        </Box>
      </ContentContainer>
    </Box>
  );
}
