import React, { useState } from "react";
import {
  Box,
  Grid,
  Container,
  FormLabel,
  Typography,
  ThemeProvider,
  TextField,
  Button,
} from "@mui/material";
import { ViewTheme, EditTheme } from "../../../themes/Theme";
import Time from "../../reusableComponents/Time";

export default function LogVisitDetails({ selectedVisit }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const FormRow = ({ label, value }) => {
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={3} lg={2.5}>
          <FormLabel>
            <Typography variant="h7">{label}</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
        </Grid>
        <Grid
          item
          xs={8.5}
          lg={9}
          sx={{ marginBottom: isEditMode ? "-5px" : "5px" }}
        >
          <Typography variant={isEditMode ? "body8" : "body4"}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const statusCard = (value) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: (() => {
            const stringValue = String(value); // Convert to string for reliable comparison
            console.log("Converted Value:", stringValue); // Debugging: check converted value
            return stringValue === "Completed"
              ? "#279989"
              : stringValue === "In Progress"
              ? "#56ACE0"
              : stringValue === "Action Required"
              ? "#FF8080"
              : stringValue === "Follow Up"
              ? "#CF75E6"
              : "transparent";
          })(),
          borderRadius: "5px",
          color: "white",
          width: "auto",
          justifyContent: "center",
          padding: "5px 10px",
        }}
      >
        {value}
      </Box>
    );
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
  };

  const commentCard = (value) => {
    return (
      <TextField
        nolabel
        multiline={true}
        value={value}
        rows={4}
        className="multiline-textfield"
        placeholder="Add Comments"
        sx={{ minWidth: "100%" }}
        disabled={isEditMode ? false : true}
      />
    );
  };

  return (
    <ThemeProvider theme={isEditMode ? EditTheme : ViewTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "97%",
          maxHeight: "calc(100vh - 340px)",
          margin: "15px 20px",
          overflow: "scroll",
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            //paddingTop: "10px",
            width: { xxl: "50%", lg: "70%", xs: "98%" },

            textAlign: "left",
          }}
        >
          {!isEditMode && (
            <Box
              sx={{
                position: "absolute",
                right: "20px", // Align to the right side
                top: "30px", // Align to the top of the container
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="outlinedSecondary" onClick={handleEditClick}>
                Edit
              </Button>
            </Box>
          )}
          <Grid container direction="row" alignItems="top" gap={2}>
            <FormRow label="Name" value={selectedVisit.clientName} />
            <FormRow label="Visit Date" value={selectedVisit.clientName} />
            <FormRow
              label="Status"
              value={statusCard([selectedVisit.visitStatus])}
            />
            <FormRow label="Comments" value={commentCard()} />
            <FormRow label="Planned Time" value="13:00 - 14:00" />
            {!isEditMode ? (
              <FormRow label="Actual Time" value="12:59 - 13:59" />
            ) : (
              <>
                <FormRow label="Actual Start Time" value={<Time />} />
                <FormRow label="Actual End Time" value={<Time />} />
              </>
            )}
            <FormRow label="Spent Time" value="60 mins" />
            <FormRow
              label="Address"
              value="The Watermark Studios, Preston, PR15EZ"
            />
            <FormRow label="Carers" value="Jorryn Ashley, Aslam Delany" />
            <FormRow label="Run Name" value="Morning Run 1" />
            {isEditMode && (
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Button variant="customcancel" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button variant="customsave"> Save</Button>
              </Box>
            )}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
